import EasyPaisaInstructions from "../components/container/EasyPaisaInstructions";
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from "react-router-dom";
import PaymentMethodV2 from "../components/container/PaymentMethodV2";
import SuccessScreen from "../components/container/SuccessScreen";
import BankSuccess from "../components/container/BankSuccess";
import AccountInfo from "../components/container/AccountInfo";
import Receipt from "../components/container/Receipt";
import PayNow from "../components/container/PayNow";
import HomeV2 from "../components/container/HomeV2";
import { path, unique_code } from "../constants";
import Home from "../components/container/Home";
import React, { useEffect } from "react";
import EasyPaisaApp from "../components/container/EasyPaisaApp";
import OneBill from "../components/container/OneBill";
import IBFT from "../components/container/IBFT";
import AccountDetail from "../components/container/IBFT/AccountDetail";
import OneBillDetail from "../components/container/OneBill/OneBillDetail";
import Card from "../components/container/Card";
import VerifyWallet from "../components/container/VerifyWallet";
import { TxnsDetailProvider } from "../context/TxnsDetailContext";
import Banks from "../components/container/Banks";
import BankDetail from "../components/container/Banks/BankDetail";

// Component to scroll to the top on route change
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location.pathname]); // Trigger effect when the route changes

  return null; // This component doesn't render anything
};

const Routes = () => {
  return (
    <Router>
      <TxnsDetailProvider >
        <ScrollToTop />
        <Switch>
          <Route
            path={"/easypaisa_instructions"}
            component={EasyPaisaInstructions}
          />
          <Route path={"/bafl/return"} exact component={BankSuccess} />
          <Route path={"/payment_method"} component={PaymentMethodV2} />
          <Route path={"/success_screen"} component={SuccessScreen} />
          <Route path={"/account_info"} component={AccountInfo} />
          <Route path={[`/i/${path[2]}/${path[3]}/paynow`, `/s/${path[2]}/${path[3]}/paynow`]} component={PayNow} />
          <Route path={[`/i/${path[2]}/${path[3]}/receipt`, `/s/${path[2]}/${path[3]}/receipt`]} component={Receipt} />
          <Route path={[`/i/${path[2]}/${path[3]}/easypaisa-app`, `/s/${path[2]}/${path[3]}/easypaisa-app`]} component={EasyPaisaApp} />
          <Route path={[`/i/${path[2]}/${path[3]}/one-bill`, `/s/${path[2]}/${path[3]}/one-bill`]} component={OneBill} />
          <Route path={[`/i/${path[2]}/${path[3]}/one-bill-detail`, `/s/${path[2]}/${path[3]}/one-bill-detail`]} component={OneBillDetail} />
          <Route path={[`/i/${path[2]}/${path[3]}/ibft`, `/s/${path[2]}/${path[3]}/ibft`]} component={IBFT} />
          <Route path={[`/i/${path[2]}/${path[3]}/ibft-detail`, `/s/${path[2]}/${path[3]}/ibft-detail`]} component={AccountDetail} />
          <Route path={[`/i/${path[2]}/${path[3]}/verify-wallet`, `/s/${path[2]}/${path[3]}/verify-wallet`]} component={VerifyWallet} />
          <Route path={[`/i/${path[2]}/${path[3]}/`, `/s/${path[2]}/${path[3]}`]} exact component={HomeV2} />
          <Route path={[`/${path[1]}`, `/${path[1]}/v2`, `/${unique_code}`, `/c/${unique_code}`]} exact component={Home} />
          <Route path={[`/i/${path[2]}/${path[3]}/card`, `/s/${path[2]}/${path[3]}/card`]} component={Card} />
          <Route path={[`/i/${path[2]}/${path[3]}/success-screen`, `/s/${path[2]}/${path[3]}/success-screen`]} component={SuccessScreen} />

          <Route path={`/${path[1]}/bank-detail`} component={BankDetail} />
          <Route path={`/${path[1]}/banks`} component={Banks} />


        </Switch>
      </TxnsDetailProvider>
    </Router>
  );
};

export default Routes;
