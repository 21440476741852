import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import React from "react";
import "./style.css";
import {
  AppFloatingLableInput,
  ParagraphText,
  PrimaryButton,
} from "../../common";

const PaymentMethod = ({
  setAccountNumber,
  otpRequestLoad,
  accountNumber,
  handleNext,
  setErrors,
  method,
  errors,
}) => {

  const handleChange = (text) => {
    setErrors({ ...errors, accountNumber: "" });

    const newText = text.replace(/\D/g, "");
    if (newText.length === 11) {
      logEvent(analytics, "account_number_entered");
    }
    setAccountNumber(newText);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleNext();
  };

  return (
    <>
      <h1 className="fontSize26 fontWeight700 darkGrayColor">
        {method == "jazzcash" ? "Jazzcash" : "Easypaisa"} account
      </h1>

      <form onSubmit={onSubmit} className="BodyContentTop paymentFieldSection paymentScreenBox">
        <div className="inputBoxMain">
          <AppFloatingLableInput
            data={{
              title: `Enter ${method == "jazzcash" ? "Jazzcash" : "Easypaisa"
                } account number  `,
              value: accountNumber,
              maxLength: 11,
              autoFocus: true,
              onChange: (e) => handleChange(e.target.value),
              info: `Aapka account number aapka ${method == "jazzcash" ? "Jazzcash" : "Easypaisa"
                } phone number hota hai `,
            }}
          />
          {errors?.accountNumber ? (
            <p className="errorMsg1"> {errors?.accountNumber} </p>
          ) : null}
          <ParagraphText className="fontSize10 mediumGrayColor">
            <span>Aapka account number aapka</span>
            <span className="marLeft3 marRight3">
              {method == "jazzcash" ? "Jazzcash" : "Easypaisa"}
            </span>
            <span>phone number hota hai</span>
          </ParagraphText>
        </div>

        <div className="inputBoxbutton">
          <PrimaryButton
            data={{
              className: `width40 fontSize16 fontWeight600 textUppercase buttonStyle ${accountNumber.length == 11
                ? "BtnGreenBg whiteColor"
                : "BtnwhiteBg lightGrayColor BtnDisable"
                }`,
              disabled: Boolean(accountNumber.length != 11 || otpRequestLoad),
              loading: otpRequestLoad,
              title: "next",
              type: "submit",
            }}
          />
        </div>
      </form>
    </>
  );
};

export default PaymentMethod;
