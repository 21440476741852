import { ParagraphText } from "../../common";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { Spinner } from "reactstrap";
import "./style.css";
import {
  formate_month_yy,
  handleResetRoute,
  formatAMPM,
  formatNum,
} from "../../../constants";
import {
  EasypaisaIcon,
  PoweredbyIcon,
  DoneTcikIcon,
  JazzcahIcon,
  VisaIcon,
  Uicon,
} from "../../../assets/icons/newicon/icon";

const SuccessScreen = ({
  referenceId,
  successLoad,
  payDetails,
  handleDone,
  payMethod,
}) => {
  const history = useHistory();

  useEffect(() => {
    handleResetRoute(payDetails, history);
  }, []);

  const handleFee = () => {
    let methodFee = payDetails?.payment_methods?.find(
      (x) => x.name == payMethod
    );
    if (payMethod == "jazzcash" || payMethod == "easypaisa") {
      methodFee =
        (Number(methodFee?.cashinfees) * Number(payDetails?.amount)) / 100;
    } else {
      methodFee = methodFee?.cashinfees;
    }
    return methodFee || 0;
  };

  return (
    <div className="successScreenBox">
      <ParagraphText className="textCenter marBot15">
        <DoneTcikIcon />
      </ParagraphText>
      <h1 className="fontSize26 fontWeight700 darkGrayColor textCenter marBot25">
        Transaction successful
      </h1>
      <div className="transactionListInnerBox receiptBoxMain">
        <ParagraphText className="textCenter marBot15">
          <PoweredbyIcon />
        </ParagraphText>
        <h2 className="fontSize30 fontWeight600 darkGrayColor textCenter marTop20 marBot0">
          Rs. {formatNum(payDetails?.amount)}
        </h2>

        <ParagraphText className="fontSize10 fontWeight400 darkGrayTwoColor textCenter marBot10">
          {/* {capitalizeFirstLetter(receiptObj.status)} */}
        </ParagraphText>

        <div className="receiptBoxItem">
          <ParagraphText className="receiptBoxItemLeft">
            <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
              From
            </span>
            <span className="fontSize12 fontWeight500 darkGrayColor OneLineTruncate">
              {payDetails?.store_name}
            </span>
            <span className="fontSize12 fontWeight500 darkGrayColor">
              {payDetails?.phone_number}
            </span>
            <span className="fontSize12 fontWeight400 darkGrayTwoColor">
              {payMethod == "jazzcash"
                ? "Jazz cash"
                : payMethod == "easypaisa"
                  ? "Easypaisa"
                  : ""}
            </span>
          </ParagraphText>
          <ParagraphText className="receiptBoxItemRight">
            {payMethod == "jazzcash" ? (
              <JazzcahIcon />
            ) : payMethod == "easypaisa" ? (
              <EasypaisaIcon />
            ) : (
              <VisaIcon />
            )}
          </ParagraphText>
        </div>

        <div className="receiptBoxItem">
          <ParagraphText className="receiptBoxItemLeft">
            <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
              To
            </span>
            <span className="fontSize12 fontWeight500 darkGrayColor OneLineTruncate">
              {payDetails?.customer_name}
            </span>
            <span className="fontSize12 fontWeight500 darkGrayColor">
              {payDetails?.customer_phone}
            </span>
            <span className="fontSize12 fontWeight400 darkGrayTwoColor">
              Udhaar book
            </span>
          </ParagraphText>
          <ParagraphText className="receiptBoxItemRight">
            <Uicon />
          </ParagraphText>
        </div>

        <div className="receiptBoxItem">
          <ParagraphText className="receiptBoxItemLeft">
            <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
              Date
            </span>
            <span className="fontSize12 fontWeight500 darkGrayColor">
              {formate_month_yy(new Date())}
            </span>
            <span className="fontSize12 fontWeight400 darkGrayTwoColor">
              {formatAMPM(new Date())}
            </span>
          </ParagraphText>
        </div>
        <div className="receiptBoxItem">
          <ParagraphText className="receiptBoxItemLeft">
            <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
              Transaction ID
            </span>
            <span className="fontSize12 fontWeight500 darkGrayColor">
              {referenceId}
            </span>
            <span className="fontSize12 fontWeight400 darkGrayTwoColor"></span>
          </ParagraphText>
        </div>
        {handleFee() > 0 ? (
          <div className="receiptBoxItem">
            <ParagraphText className="receiptBoxItemLeft">
              <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                Fee
              </span>
              <span className="fontSize12 fontWeight500 darkGrayColor">
                {`Rs. ${formatNum(handleFee())}`}
              </span>
              <span className="fontSize12 fontWeight400 darkGrayTwoColor"></span>
            </ParagraphText>
          </div>
        ) : null}
      </div>
      <button class="nextButton nextCustomWidth" onClick={handleDone} disabled={successLoad}>
        {successLoad ? <Spinner /> : 'DONE'}
      </button>
    </div>
  );
};

export default SuccessScreen;
