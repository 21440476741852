import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import {
  CopyIcon,
  DownIcon,
  EasypaisaIcon,
  SaveIcon,
  ShareIcon,
  UserIcon,
  VisaIcon,
  YoutubeIcon,
} from "../../../assets/icons/newicon/icon";
import {
  AppFloatingLableInput,
  AppHeader,
  DeatailCard,
  InnerHeader,
  ParagraphText,
  PrimaryButton,
} from "../../common";
import Accordion from "react-bootstrap/Accordion";
import { stepList } from "./StepList";
import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { handleResetRoute } from "../../../constants";

const Card = ({ location, history }) => {
    const { payDetails } = useTxnsDetailContext();
  
    useEffect(() => {
      handleResetRoute(payDetails, history)
    }, []);

  const handleNameChange = () => {
    // handleNameChange
  };
  return (
    <div>
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />{" "}
      <div className="homeBodyBox">
        <InnerHeader history={history} visible={true} />
        <Container>
          <Row>
            <Col>
              <h1 className="fontSize26 fontWeight700 darkGrayColor">
                Enter card details
              </h1>

              <div className="cardNumberBox">
                <AppFloatingLableInput
                  data={{
                    title: "Card number",
                    value: "", // Controlled value
                    onChange: handleNameChange, // Update parent state
                    prefixIcon: <VisaIcon />,
                  }}
                />
                <div className="cardCvcBox">
                  <AppFloatingLableInput
                    data={{
                      title: "MM/YY",
                      value: "", // Controlled value
                      onChange: handleNameChange, // Update parent state
                      /* prefixIcon: <UserIcon />, */
                    }}
                  />
                  <AppFloatingLableInput
                    data={{
                      title: "CVC",
                      value: "", // Controlled value
                      onChange: handleNameChange, // Update parent state
                      /* prefixIcon: <UserIcon />, */
                    }}
                  />
                </div>
                <h2 className="fontSize18 fontWeight700 darkGrayColor marBot15">
                  Card holder name
                </h2>
                <AppFloatingLableInput
                  data={{
                    title: "Enter full name on card",
                    value: "", // Controlled value
                    onChange: handleNameChange, // Update parent state
                    /* prefixIcon: <VisaIcon />, */
                  }}
                />
                <h2 className="fontSize18 fontWeight700 darkGrayColor marTop20 marBot15">
                  Country or region
                </h2>
                <AppFloatingLableInput
                  data={{
                    title: "Select country",
                    value: "", // Controlled value
                    onChange: handleNameChange, // Update parent state
                    /* prefixIcon: <VisaIcon />, */
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="homeActionBox cardActionBox">
          <PrimaryButton
            data={{
              className:
                "width40 fontSize16 fontWeight600 textUppercase buttonStyle BtnGreenBg whiteColor",
              title: "Submit",
              type: "submit",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
