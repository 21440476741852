import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { LanguageContext } from "../../../context/LanguageContext";
import { OneBillIcon } from "../../../assets/icons/newicon/icon";
import { handleResetRoute } from "../../../constants";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import "./style.css";
import {
  BankDetailCard,
  ParagraphText,
  PrimaryButton,
  InnerHeader,
  AppHeader,
} from "../../common";

const OneBillDetail = ({ history }) => {
  const { selectedBank } = history.location || {};
  const { display_name, tutorial_link_1bill } = selectedBank || {};

  const { payDetails } = useTxnsDetailContext();
  const [I18n] = useContext(LanguageContext);

  useEffect(() => {
    handleResetRoute(payDetails, history)
  }, []);

  const handleBankDetail = () => {
    // handleBankDetail
  };
  const handleCopy = async () => {

    try {
      await navigator.clipboard.writeText(payDetails?.consumer_number);
      toast.success("Copied");
    } catch (error) { }
  };

  const handleBankVideo = () => {
    if (tutorial_link_1bill) window.open(`https://www.youtube.com/shorts/${tutorial_link_1bill}`)
    else toast.error("Video not available")
  };

  return (
    <div>
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />
      <div
        className="homeBodyBox"
      >
        <InnerHeader history={history} visible={true} />
        <Container>
          <Row>
            <Col>
              <h1 className="fontSize26 fontWeight700 darkGrayColor">
                {display_name}
              </h1>
              <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20">
                Instruction for 1Bill
              </ParagraphText>
              <div className="StepsBoxWrapper">
                {selectedBank && Object.keys(selectedBank).length ? (I18n.lang == 'en' ? selectedBank?.steps_1bill?.en : selectedBank?.steps_1bill?.ur).map((item, index) => {
                  // const { heading, title } = item;
                  return (
                    <div className="easypaisaAppStepsBoxList" key={index}>
                      <ParagraphText className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                        {`Step ${index + 1}`}
                      </ParagraphText>
                      <ParagraphText className="fontSize12 fontWeight500 darkGrayColor">
                        {item}
                      </ParagraphText>
                    </div>
                  );
                }) : null}
              </div>

              <BankDetailCard
                data={{
                  key: 1,
                  icon: <OneBillIcon />,
                  heading: payDetails?.consumer_number,
                  title: "Consumer ID",
                  paragraph: "1 bill",
                  handleClick: handleBankDetail,
                  handleCopy: handleCopy,
                }}
              />
            </Col>
          </Row>
        </Container>
        <div className="homeActionBox">
          <PrimaryButton
            data={{
              className: "width40 fontSize16 fontWeight600 textUppercase buttonStyle BtnGreenBg whiteColor",
              title: "Watch Tutorial",
              type: "submit",
              onClick: handleBankVideo,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OneBillDetail;
