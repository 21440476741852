import React, { useState, useEffect } from "react";
import { getBanks } from "../../../SyncServices";
import { Col, Container, Row } from "reactstrap";
import BankList from "../../common/BankList";
import { path } from "../../../constants";
import { Spinner } from "react-bootstrap";
import "../OneBill/style.css";

import {
    AppFloatingLableInput,
    ParagraphText,
    InnerHeader,
} from "../../common";

const Banks = ({ history }) => {

    const [list, setList] = useState({ allBanks: [], searchedBanks: [] });
    const [selectedBank, setSelectedBank] = useState("");
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleBanks();
    }, []);

    const handleBanks = () => {
        getBanks().then(res => handleList(res.banks)).finally(() => setLoading(false))
    };

    const handleList = (banks) => {
        let bankList = groupItemsForSectionList(banks);
        setList({ allBanks: banks, searchedBanks: bankList });
    };

    const handleSelectBank = (bankDetail) => {
        setSelectedBank(bankDetail);
        history.push({
            pathname: `/${path[1]}/bank-detail`,
            consumer_number: path[1],
            selectedBank: bankDetail,
        });
    };

    const groupItemsForSectionList = (items) => {
        let grouped = {};
        let wallets = [];
        let popular = [];

        items.forEach((item) => {
            if (item.display_name) {
                const isWallet =
                    item?.display_name?.toLowerCase() == "jazzcash" ||
                    item?.display_name?.toLowerCase() == "easypaisa";

                const key =
                    item?.display_name?.length > 0 && item.display_name[0]?.toUpperCase();

                if (isWallet) {
                    wallets.push(item);
                } else {
                    if (!grouped[key]) {
                        grouped[key] = [];
                    }
                    grouped[key].push(item);
                }
            }
        });

        if (wallets.length > 0) {
            popular.push({
                title: "Popular",
                data: wallets,
            });
        }

        const sections = Object.keys(grouped)
            .sort()
            .map((key) => ({
                title: key,
                data: grouped[key],
            }));

        return [...popular, ...sections];
    };

    const handleChange = (e) => {
        let val = e.target.value;
        setSearchText(val);

        const filteredBanks = list.allBanks?.filter((x) =>
            x?.display_name?.toLowerCase().includes(val?.toLowerCase())
        );

        const sectionedData = groupItemsForSectionList(filteredBanks);
        setList({ ...list, searchedBanks: sectionedData });
    };

    return loading ? (
        <div className="LoadingScreen">
            <Spinner animation="border" className="loaderCircle" />
        </div>
    ) :
        <div>
            <div className="homeBodyBox">
                <InnerHeader history={history} visible={true} />
                <Container>
                    <Row>
                        <Col>
                            <h1 className="fontSize26 fontWeight700 darkGrayColor">
                                Select bank
                            </h1>
                            <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20">
                                1 bill payment ke leye apna bank select karein
                            </ParagraphText>
                            <div className="selectBankBox">
                                <AppFloatingLableInput
                                    data={{
                                        onChange: handleChange,
                                        title: "Search bank",
                                        value: searchText,
                                    }}
                                />
                            </div>
                            <div className="bankListBoxWrapper">
                                {Object.values(list.searchedBanks)?.length
                                    ? Object.values(list.searchedBanks)?.map((item) => (
                                        <BankList
                                            handleSelectBank={handleSelectBank}
                                            selectedBank={selectedBank}
                                            banks={item}
                                        />
                                    ))
                                    : "Koi bank nahi mila"}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
};

export default Banks;
