import {
  capitalizeFirstLetter,
  formatAMPM,
  formatNum,
  formateDate_dd_month_yy,
  formate_month_yy,
  path,
} from "../../../constants";
import { getPaymentDetails } from "../../../SyncServices";
import { img_nonIdeal } from "../../../assets/images";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AppFooter, AppHeader, InnerHeader, ParagraphText } from "../../common";
import { Spinner } from "reactstrap";
import "./style.css";
import { DownIcon, Uicon } from "../../../assets/icons/newicon/icon";
import Accordion from "react-bootstrap/Accordion";
import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";

const Receipt = ({ history }) => {
  const { phone_number } = history.location?.state || {};

  const { loading, payDetails } = useTxnsDetailContext();

  return loading ? (
    <div className="LoadingScreen">
      <Spinner animation="border" className="loaderCircle" />
      <p>Loading</p>
    </div>
  ) : Object.values(payDetails)?.length ? (
    <div>
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />
      <div
        className="homeBodyBox"
      >
        <InnerHeader
          history={history}
          visible={
            !Boolean(
              payDetails?.status == "paid" || payDetails?.bill_type == "out"
            )
          }
        />
        <Container>
          <Row>
            <Col>
              <h1 className="fontSize26 fontWeight700 darkGrayColor textCenter">
                Payment details
              </h1>
              <div className="transactionListInnerBox">
                <h2 className="fontSize30 fontWeight600 darkGrayColor textCenter marTop20 marBot0">
                  Rs. {formatNum(payDetails?.amount || 0)}
                </h2>

                <ParagraphText className="fontSize10 fontWeight400 darkGrayTwoColor textCenter marBot10">
                  {payDetails?.status && capitalizeFirstLetter(payDetails?.status)}
                </ParagraphText>

                <div className="receiptBoxItem">
                  <ParagraphText className="receiptBoxItemLeft">
                    <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                      From
                    </span>
                    <span className="fontSize12 fontWeight500 darkGrayColor OneLineTruncate">
                      {payDetails?.store_name}
                    </span>
                    <span className="fontSize12 fontWeight500 darkGrayColor">
                      {payDetails?.phone_number}
                    </span>
                    <span className="fontSize12 fontWeight400 darkGrayTwoColor">
                      Udhaar book
                    </span>
                  </ParagraphText>
                  <ParagraphText className="receiptBoxItemRight">
                    <Uicon />
                  </ParagraphText>
                </div>

                <div className="receiptBoxItem">
                  <ParagraphText className="receiptBoxItemLeft">
                    <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                      To
                    </span>
                    <span className="fontSize12 fontWeight500 darkGrayColor OneLineTruncate">
                      {payDetails?.customer_name}
                    </span>
                    <span className="fontSize12 fontWeight400 darkGrayTwoColor">
                      {payDetails?.customer_phone}
                    </span>
                  </ParagraphText>
                </div>

                <div className="receiptBoxItem">
                  <ParagraphText className="receiptBoxItemLeft">
                    <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                      Date
                    </span>
                    <span className="fontSize12 fontWeight500 darkGrayColor">
                      {payDetails?.date && formate_month_yy(payDetails?.date)}
                    </span>
                    <span className="fontSize12 fontWeight400 darkGrayTwoColor">
                      {payDetails?.date && formatAMPM(payDetails?.date)}
                    </span>
                  </ParagraphText>
                </div>
                {payDetails?.discount_method ? (
                  <div className="receiptBoxItem">
                    <ParagraphText className="receiptBoxItemLeft">
                      <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                        Discount
                      </span>
                      <span className="fontSize12 fontWeight500 darkGrayColor">
                        Rs.{" "}
                        {formatNum(
                          payDetails?.discount_method == "rs"
                            ? payDetails?.discount
                            : (payDetails?.discount / 100) * payDetails?.amount
                        )}
                      </span>
                    </ParagraphText>
                  </div>
                ) : null}

                {payDetails?.items?.length ? (
                  <Accordion className="itemsListAccordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="receiptBoxItem">
                          <ParagraphText className="receiptBoxItemLeft">
                            <span className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                              Item purchased
                            </span>
                            <span className="fontSize12 fontWeight500 darkGrayColor">
                             {payDetails?.items?.length || 0} items
                            </span>
                          </ParagraphText>
                          <ParagraphText className="receiptBoxItemRight">
                            <Accordion />
                            <DownIcon />
                          </ParagraphText>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="transactionListTable">
                          <table>
                            <thead>
                              <th>
                                <p>Item</p>
                              </th>
                              <th>
                                <p>Qty</p>
                              </th>
                              <th>
                                <p>Discount</p>
                              </th>
                              <th>
                                <p>Price</p>
                              </th>
                            </thead>
                            {payDetails?.items.map((item, index) => {
                              const {
                                discount_method,
                                image_url,
                                discount,
                                quantity,
                                price,
                                name,
                              } = item || {};

                              let disc = discount
                                ? discount_method == "rs"
                                  ? discount
                                  : (discount / 100) * price
                                : 0;

                              return (
                                <tr key={index}>
                                  <td>
                                    <p className="transactionItemBox">
                                      <span className="transactionItemImage">
                                        <img
                                          src={image_url || img_nonIdeal}
                                          title=""
                                          alt=""
                                        />
                                      </span>
                                      <span className="marLeft10">{name}</span>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{quantity}</p>
                                  </td>
                                  <td>
                                    <p>Rs. {formatNum(disc)}</p>
                                  </td>
                                  <td>
                                    <p>Rs. {formatNum(price)}</p>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
        {/* <section
          className={`transactionListInnerBox ${
            receiptObj?.items?.length ? "heightWithItem" : "heightWithoutItem"
          }`}
        > */}
        <AppFooter />
      </div>
    </div>
  ) : null;
};

export default Receipt;
