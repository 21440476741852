import React from "react";
import ParagraphText from "../ParagraphText";
import "./style.css";

const DeatailCard = ({ data }) => {
  const { heading, title, paragraph, actions, icon, image } = data;
  return (
    <div className="detailBoxWrapper">
      <ParagraphText className="detailBoxAppIcon">
        {icon ? (
          icon
        ) : (
          <img className="UdhaarLogo" src={image} alt="" title="" />
        )}
      </ParagraphText>
      <h2 className="fontSize30 fontWeight600 darkGrayColor textCenter marTop20 marBot5">
        {heading}
      </h2>

      <ParagraphText className="fontSize18 fontWeight400 darkGrayColor textCenter marBot10">
        {title}
      </ParagraphText>
      <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor textCenter marBot0">
        {paragraph}
      </ParagraphText>
      <div className="detailBoxAction">
        {actions.map((action, index) => (
          <p
            key={index}
            className="cursorPointer fontSize12 fontWeight500 darkGrayColor"
            onClick={action.handleClick}
          >
            <span className="marBot5">{action.icon}</span>
            <span>{action.label}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default DeatailCard;
