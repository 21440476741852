import { Container, Col, Row, Spinner } from "react-bootstrap";
import { formatNum } from "../../../constants";
import ParagraphText from "../ParagraphText";
import { PdfIcon, RightArrowIcon } from "../../../assets/icons/newicon/icon";
import "./style.css";

const AmountCard = ({ data }) => {
  const { handleReceipt, handleDownload, amount, title, tel, loading } = data || {};

  return (
    <section className="paymentAmountCard">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="paymentAmountCardInner">
              <h1 className="fontSize18 fontWeight600 OneLineTruncate darkGrayColor marBot0">
                Invoice bill
                <span className="downloadPdfIcon" onClick={handleDownload}>
                  {loading ? <Spinner animation="border" size="sm" className="loaderCircle" /> : <PdfIcon />}
                </span>
              </h1>
              <ParagraphText className="fontSize30 fontWeight700 darkGrayColor marTop10 marBot10 amountPara">
                Rs. {formatNum(amount)}
              </ParagraphText>
              <ParagraphText
                className="fontSize14 mediumGrayColor cursorPointer"
                onClick={handleReceipt}
              >
                <span className="textUnderline">Details</span>
                <span className="marLeft5 rightArrowIcon">
                  <RightArrowIcon />
                </span>
              </ParagraphText>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AmountCard;
