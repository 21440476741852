import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { getConsumerID, postDownloadPDF } from "../../../SyncServices";
import { LanguageContext } from "../../../context/LanguageContext";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { LockIcon } from "../../../assets/icons/newicon/icon";
import { path, REPORT_URL } from "../../../constants";
import { link_expire } from "../../../assets/images";
import React, { useState, useContext } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { toast } from "react-toastify";
import "./style.css";
import {
  ContactInfomationCard,
  FloatingButtons,
  ParagraphText,
  PrimaryButton,
  PayMethods,
  AmountCard,
  AppFooter,
  AppHeader,
} from "../../common";

const HomeV2 = ({ history }) => {
  const { payDetails, setPayDetails, paymentMethods, loading, invalid } = useTxnsDetailContext();

  const [I18n] = useContext(LanguageContext);

  const [toInstalled, setToInstalled] = useState(false);
  const [payMethod, setPayMethod] = useState("");
  const [laod, setLoad] = useState({
    payLaod: false,
    download: false,
  });

  const handleReceipt = () => {
    logEvent(analytics, "payment_link_receipt", {});
    history.push(`/${path[1]}/${path[2]}/${path[3]}/receipt`);
  };

  const handlePayMethod = (event) => {
    logEvent(analytics, `payment_link_method_${event.target.value}`, {});
    if (toInstalled) setToInstalled(false);
    setPayMethod(event.target.value);
  };

  const handleDownloadInvoice = async () => {
    if (navigator.onLine) {
      try {
        setLoad({ ...laod, download: true });
        let paramsForReport = {
          template_name: `${path[1] == "i" ? "invoicereport" : "sale_invoice"
            }.hbs`,
          name: "invoice_statement.pdf",
          invoice_id: payDetails?.invoice_id,
          business_id: payDetails?.business_id,
          template_style: "style1",
          template_color: "#000000",
          pdf: true,
        };

        paramsForReport[
          "payment_link"
        ] = `${REPORT_URL}/${path[1]}/${path[2]}/${path[3]}`;

        await postDownloadPDF(paramsForReport);
        toast.success("Successfully downloaded invoice");
        setLoad({ ...laod, download: false });
      } catch (error) {
        console.log(error, "ERR");
        setLoad({ ...laod, download: false });
      }
    } else toast.error("Internet connection is required");
  };

  const handleConsumer = () => {
    if (navigator.onLine) {
      let params = {
        partner: payMethod == "Easypaisa App" ? "easypaisa-app" : payMethod,
        business_report_url: path[2],
        invoice_id: path[3],
        customer_name: payDetails?.customer_name,
        customer_phone: payDetails?.phone_number,
        unique_code: payDetails?.unique_code,
      };

      getConsumerID(params)
        .then((res) => {
          const {
            success,
            consumer_number,
            banks,
            data = [],
            steps,
            tutorial_link_easypaisa_collection,
            share_message,
          } = res || {};
          if (success) {
            if (payMethod == "card") {
              toast.error("Coming soon");
            } else {
              let pathName = `/${path[1]}/${path[2]}/${path[3]}/paynow`;
              if (payMethod == "Easypaisa App") {
                pathName = `/${path[1]}/${path[2]}/${path[3]}/easypaisa-app`;
              } else if (payMethod == "ibft") {
                pathName = `/${path[1]}/${path[2]}/${path[3]}/ibft`;
                // if (!data?.length) {
                //   toast.error("No accounts found");
                //   return;
                // }
              } else if (payMethod == "1bill") {
                pathName = `/${path[1]}/${path[2]}/${path[3]}/one-bill`;
              } else if (payMethod == "jazzcash" || payMethod == "easypaisa") {
                pathName = `/${path[1]}/${path[2]}/${path[3]}/verify-wallet`;
              }
              setPayDetails({
                ...payDetails,
                banks,
                steps,
                connectAccounts: data,
                consumer_number,
                tutorial_link_easypaisa_collection,
              });
              history.push({
                pathname: pathName,
                payMethod,
              });
            }
          } else {
            toast.error(res?.message || "An error occured");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "An error occured");
        })
        .finally(() => setLoad({ ...laod, payLaod: false }));
    } else toast.error("Internet connection is required");
  };

  const handlePayNow = () => {
    let phoneNumber = payDetails?.phone_number;
    let patt = /03[0-9]{2}(?!1234567)(?!1111111)(?!7654321)[0-9]{7}/; // VALIDATION FOR PAKISTANI MOBILE NUMBER

    if (phoneNumber.length != 11 || phoneNumber.match(patt) == null) {
      toast.error(I18n.phone_number_format);
      return;
    } else {
      logEvent(analytics, `payment_link_method_selected_paynow_cta`, {});

      if (navigator.onLine) {
        setLoad({ ...laod, payLaod: true });

        if (payMethod == "easypaisa" || payMethod == "jazzcash") {
          history.push({
            pathname: `/${path[1]}/${path[2]}/${path[3]}/verify-wallet`,
            payMethod,
            payDetails,
          });
        } else {
          handleConsumer();
        }
      } else toast.error("Internet connection is required");
    }
  };

  const handleCall = () => {
    window.location.href = `tel:${payDetails?.phone_number}`;
  };

  return loading ? (
    <div className="LoadingScreen">
      <Spinner animation="border" className="loaderCircle" />
    </div>
  ) : invalid ? (
    <section className="NotFoundMainWrapper">
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />
      <div className="homeBodyBox">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="NotFoundWrapper">
                <img
                  className="notFoundImage"
                  src={link_expire}
                  alt=""
                  title=""
                />
                <h1 className="fontSize26 fontWeight600 darkGrayColor marTop10 marBot10 textCenter">
                  Maazrat, Link Khataam Ya Delete Ho Gaya Hai!
                </h1>
                <p className="fontSize14 fontWeight400 darkGrayColor marTop10 marBot10 textCenter">
                  Agar aap ne pay kar diya hai to apni receipt check karein.
                  Agar nahi, to neeche diye gaye options use karein.
                </p>
                <button
                  class="nextButton width80 OneLineTruncate"
                  onClick={handleCall}
                >
                  Call {payDetails?.store_name}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <AppFooter />
    </section>
  ) : (
    <div className="">
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />
      <section className="homeBodyBox">
        <div className="paymentSimpleBox">
          <ParagraphText className="fontSize10 mediumGrayColor">
            Make your payment simple, fast, and safe.
            <span className="marLeft5">
              <LockIcon />
            </span>
          </ParagraphText>
        </div>
        <AmountCard
          data={{
            tel: `tel: +92${payDetails?.phone_number?.slice(1, 11)}`,
            amount: payDetails?.amount,
            handleReceipt,
            handleDownload: handleDownloadInvoice,
            loading: laod?.download,
          }}
        />
        <ContactInfomationCard
          customerPhone={payDetails?.customer_phone || ''}
          customerName={payDetails?.customer_name}
          setPayDetails={setPayDetails}
          payDetails={payDetails}
        />
        <Container>
          <Row>
            <Col>
              <form>
                <div className="paymentMethodWrapper">
                  <h2 className="fontSize14 fontWeight700 darkGrayColor marBot10">
                    Payment method
                  </h2>
                  <PayMethods
                    paymentMethods={paymentMethods}
                    payMethod={payMethod}
                    handlePayMethod={handlePayMethod}
                  />

                  {toInstalled ? (
                    <p className="statementPara">
                      Udhaar app download karne ke liye
                      <a href="https://play.google.com/store/apps/details?id=com.oscarudhaarapp&hl=en&gl=US">
                        Click karein
                      </a>
                    </p>
                  ) : null}
                </div>
              </form>
            </Col>
          </Row>
        </Container>
        <div className="homeActionBox">
          <PrimaryButton
            data={{
              className: `width40 fontSize16 fontWeight600 textUppercase buttonStyle ${payMethod &&
                payDetails?.customer_name &&
                payDetails?.phone_number &&
                payDetails?.amount
                ? "BtnGreenBg whiteColor"
                : "BtnwhiteBg lightGrayColor BtnDisable"
                }`,
              disabled: Boolean(
                !payMethod ||
                !payDetails?.customer_name ||
                !payDetails?.phone_number ||
                !payDetails?.amount ||
                laod.payLaod
              ),
              loading: laod.payLaod,
              title: "pay",
              type: "submit",
              onClick: handlePayNow,
            }}
          />
        </div>

        <AppFooter />
      </section>
      <FloatingButtons />;
    </div>
  );
};
export default HomeV2;
