import {
  alfalahHandshake,
  getPaymentInformation,
  getPhoneInformation,
  getVoucherCode,
  handleBank,
  makePayment,
  modifyVerifiedSession,
  putCallCaptcha,
  verifyNumber,
  verifyOtpCode,
  verifyPayment,
} from "../../../SyncServices";
import { Container, Row, Col, Spinner, Modal } from "reactstrap";
import DownloadBanner from "../../common/DownloadBanner";
import {
  formatAMPM,
  formateDate_dd_month_yy,
  formateDate_dd_month,
  formatNum,
  FromVoucher,
  RECAPTCHA_SITE_KEY,
  unique_code,
} from "../../../constants";
import VerificationScreen from "../VerificationScreen";
import React, { useState, useEffect, useRef, useContext } from "react";
import { getBackupDate } from "../../../constants";
import { logEvent } from "firebase/analytics";
import ReCAPTCHA from "react-google-recaptcha";
import { analytics } from "../../../firebase";
import SuccessScreen from "../SuccessScreen";
import PaymentMethod from "../PaymentMethodV2";
import Header from "../../common/Header";
import MpinScreen from "../MpinScreen";
import VoucherPhoneInput from "../voucherPhoneInput";
import VoucherCodeScreen from "../voucherCodeScreen";
import "./style.css";
import {
  LanguageContext,
  LanguageProvider,
} from "../../../context/LanguageContext";
import ur from "../../../languageConfig/local/ur";
import en from "../../../languageConfig/local/en";

const Home = ({ history, business_name }) => {
  const codeRef = useRef();

  const [I18n, setLanguage] = useContext(LanguageContext);

  const [refrenceId, setrefrenceId] = useState("");
  const [cashbackPercentage, setCashbackPercentage] = useState("");
  const [cashbackPromotion, setCashbackPromotion] = useState("");
  const [otpRequestLoad, setOtpRequestLoad] = useState(false);
  const [showResendCode, setShowResendCode] = useState(false);
  const [alfalahStatus, setAlfalahStatus] = useState(false);
  const [verifyLoad, setVerifyLoad] = useState(false);
  const [merchantPhone, setMerchantPhone] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [customer_name, setCustomerName] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [documentID, setDocumentID] = useState("");
  const [showMpin, setShowMpin] = useState(false);
  const [mpinSend, setMpinSend] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [reportUrl, setReportUrl] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otpLoad, setOtpLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [method, setMethod] = useState("");
  const [status, setStatus] = useState("");
  const [payable, setPayable] = useState();
  const [alfalah, setAlfalah] = useState();
  const [errors, setErrors] = useState({});
  const [code, setCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [voucherData, setVoucherData] = useState("");

  useEffect(() => {
    const res = localStorage.getItem("language");

    if (res) {
      changeLag(res);
    }

    if (FromVoucher) {
      getVoucherCode(unique_code)
        .then((res) => {
          // console.log("res then ++++ ======naya wala=====", res);
          setLoading(false);
          setStatus("paid");
          if (res.captcha) {
            setShowCaptcha(true);
            setDocumentID(res.document_id);
          } else {
            // console.log("esle chal raha he bhai", res);
          }
          // setVoucherData(res);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    } else {
      logEvent(analytics, "payment_request_viewed");
      localStorage.setItem("unique_code", unique_code);
      getPaymentInformation(unique_code)
        .then((res) => {
          setLoading(false);
          if (res.status == true) {
            logEvent(analytics, "payment_pending");
            setReportUrl(res.report_url);
            setMerchantPhone(res.merchant_phone);
            setPayable(res.amount_requested);
            setbusinessName(res.business_name);
            setAlfalah(res.alfalah);
            setStatus(res.payment_status);
            setCustomerName(res.customer_name);
            setrefrenceId(res.reference_id);
            setCashbackPercentage(res.cashback_percentage);
            setCashbackPromotion(res.cashback_promotion);
            if (res.payment_status == "paid") {
              logEvent(analytics, "payment_already_paid");
              setAccountNumber(res.customer_phone);
              setShowSuccess(true);
            }
          } else {
            logEvent(analytics, "invalid_url", {
              message: res.message,
            });
            if (res.message == "Invalid Url") {
              setStatus(res.message);
              setLoading(false);
            } else {
              setStatus(res.message);
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, []);

  const toggleMpinScreen = (success = false) => {
    setShowMpin((prev) => !prev);
    if (success) setShowSuccess(true);
  };
  const toggleverificationScreen = (success = false) => {
    setShowOtp((prev) => !prev);
    setCode("");
    setInvalidCode(false);
    setCodeSent(false);
    setShowResendCode(false);
    setErrors("");
  };

  const handleNext = (e) => {
    e.preventDefault();

    if (method === "Alfalah") {
      if (alfalahStatus != false) redirectToPayment();
      else handshakeBank();
    } else {
      let patt = /03[0-9]{2}(?!1234567)(?!1111111)(?!7654321)[0-9]{7}/; // VALIDATION FOR PAKISTANI MOBILE NUMBER

      if (!accountNumber) {
        logEvent(analytics, "incorrect_phone_format");
        setErrors({
          ...errors,
          accountNumber: "Account number is required",
        });
        return;
      } else if (accountNumber.match(patt) === null) {
        logEvent(analytics, "incorrect_phone_format");
        setErrors({
          ...errors,
          accountNumber: "Account number should be in format e.g. 03001234567",
        });
        return;
      }

      if (accountNumber.length !== 11) {
        logEvent(analytics, "incorrect_phone_format");
        setErrors({
          ...errors,
          accountNumber:
            "Account number should be 11 digits (e.g. 03xx1234567)",
        });
        return;
      }

      if (accountNumber.match(patt) === null) {
        logEvent(analytics, "incorrect_phone_format");
        setErrors({
          ...errors,
          accountNumber: "Account number should be in format e.g. 03001234567",
        });
        return;
      }

      if (!method) {
        logEvent(analytics, "payment_method_required");
        setErrors({
          ...errors,
          method: "Payment method is required",
        });
        return;
      }

      setOtpRequestLoad(true);

      verifyPayment(unique_code, accountNumber, method)
        .then((res) => {
          logEvent(analytics, "verify_payment", {
            method,
          });
          if (res.captcha == true) {
            logEvent(analytics, "captcha_verification", {
              method,
            });
            setShowCaptcha(true);
          } else if (res.otp_sent == true) {
            if (code) setCode("");
            if (!showOtp) setShowOtp(true);
            if (!codeSent) setCodeSent(true);
            if (showResendCode) {
              setShowResendCode(false);
              codeRef?.current?.focusInput(0);
            }
            if (invalidCode) setInvalidCode(false);
          }
          setDocumentID(res.document_id);
          setOtpRequestLoad(false);
        })
        .catch((err) => {
          setErrors({ ...errors, request: err?.data?.message });
          setOtpRequestLoad(false);
        });
    }
  };

  const handleNextFunc = (e) => {
    e.preventDefault();

    let patt = /03[0-9]{2}(?!1234567)(?!1111111)(?!7654321)[0-9]{7}/; // VALIDATION FOR PAKISTANI MOBILE NUMBER
    if (!phoneNumber) {
      setErrors({
        phoneNumber: "Phone number is required",
      });
      return;
    } else if (phoneNumber.match(patt) === null) {
      setErrors({
        phoneNumber: "Phone number should be in format e.g. 03001234567",
      });
      return;
    } else if (phoneNumber.length !== 11) {
      setErrors({
        phoneNumber: "Phone number should be 11 digits (e.g. 03xx1234567)",
      });
      return;
    }

    if (phoneNumber.match(patt) === null) {
      setErrors({
        phoneNumber: "Phone number should be in format e.g. 03001234567",
      });
      return;
    }

    setOtpRequestLoad(true);

    verifyNumber(unique_code, phoneNumber)
      .then((res) => {
        console.log("res in ", res);
        if (res.otp_sent == true) {
          setOtpLoad(true);
          if (code) setCode("");
          if (!showOtp) setShowOtp(true);
          if (!codeSent) setCodeSent(true);
        }
        setDocumentID(res.document_id);
        setOtpRequestLoad(false);
      })
      .catch((err) => {
        // console.log("error he screen me ", err?.data?.message);
        setErrors({ phoneNumber: err?.data?.message });
        setOtpRequestLoad(false);
      });
  };

  const verifyDeposit = (val) => {
    if (FromVoucher) {
      // if (val.length == 4) {
      // setShowOtp(false);
      // setShowSuccess(true);
      // }
      setVerifyLoad(true);

      let PARAMS = {
        payment_process: "voucher",
        document_id: documentID,
        otp: val,
        phone_number: phoneNumber,
        unique_code: unique_code,
      };
      if (showOtp) PARAMS["otp"] = val;

      verifyOtpCode(PARAMS)
        .then((res) => {
          if (res.verified == true) {
            // if (otpLoad) setOtpLoad(false);
            // proceedToPay();
            setShowOtp(false);
            setShowSuccess(true);
          }

          logEvent(
            analytics,
            res.verified == true
              ? "Voucher_verified"
              : res.otp_sent == true && "otp_sent"
          );
        })
        .catch((err) => {
          logEvent(analytics, showOtp && "otp_failed", {
            error: err?.data?.message || "unrecognised_error",
          });

          if (showOtp) {
            codeRef.current.focusInput(0);
            setCode("");
            setInvalidCode(true);
            // setCodeSent(false);
          } else {
            setErrors({ captcha: err.data?.message });
          }
          setVerifyLoad(false);
        });
    } else {
      setVerifyLoad(true);

      let PARAMS = {
        payment_process: "online_payment",
        document_id: documentID,
        unique_code,
      };

      if (showCaptcha) PARAMS["g-recaptcha-response"] = val;
      else if (showOtp) PARAMS["otp"] = val;

      modifyVerifiedSession(PARAMS)
        .then((res) => {
          if (res.verified == true) {
            proceedToPay();
          } else if (res.otp_sent == true) {
            if (showCaptcha) setShowCaptcha(false);
            setCodeSent(true);
            setShowOtp(true);
            setVerifyLoad(false);
          }
          logEvent(
            analytics,
            res.verified == true
              ? "payment_verified"
              : res.otp_sent == true && "otp_sent"
          );
        })
        .catch((err) => {
          logEvent(
            analytics,
            showCaptcha ? "captcha_failed" : showOtp && "otp_failed",
            {
              error: err?.data?.message || "unrecognised_error",
            }
          );

          if (showOtp) {
            codeRef.current.focusInput(0);
            setCode("");
            setInvalidCode(true);
          } else {
            setErrors({ ...errors, captcha: err.data?.message });
          }
          setVerifyLoad(false);
        });
    }
  };

  const handshakeBank = () => {
    setOtpRequestLoad(true);
    alfalahHandshake({ unique_code })
      .then((res) => {
        if (res.success == false) alert(res.message);
        else setAlfalahStatus(res.step_2_request_body);
      })
      .finally(() => {
        setOtpRequestLoad(false);
      });
  };

  const redirectToPayment = () => {
    setOtpRequestLoad(true);
    handleBank(alfalahStatus)
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setOtpRequestLoad(false);
      });
  };

  const onChange = (value) => {
    logEvent(analytics, "captcha_provided");
    if (FromVoucher) {
      setShowCaptcha(false);
      putCallCaptcha(documentID, unique_code, value)
        .then((res) => {
          // console.log("putCallCaptcha======", res);
          // setVoucherData(res);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      verifyDeposit(value);
    }
  };

  const proceedToPay = () => {
    makePayment(unique_code, accountNumber, method, documentID)
      .then((res) => {
        if (showOtp) setShowOtp(false);
        if (codeSent) setCodeSent(false);
        if (showCaptcha) setShowCaptcha(false);
        if (showResendCode) setShowResendCode(false);
        if (code) setCode("");

        setVerifyLoad(false);
        setMpinSend(true);
        toggleMpinScreen();
      })
      .catch((err) => {
        if (showOtp) setShowOtp(false);
        if (codeSent) setCodeSent(false);
        if (showCaptcha) setShowCaptcha(false);
        if (code) setCode("");
        setVerifyLoad(false);

        logEvent(analytics, "payment_failed", {
          method,
          error: err.data?.message || "unrecognised_error",
        });
      });
  };

  const changeLag = (lang) => {
    if (lang == "ur") {
      setLanguage(ur);
    } else if (lang == "en") {
      setLanguage(en);
    }

    localStorage.setItem("language", lang);
  };

  return (
    <>
      {loading ? (
        <div className="LoadingScreen">
          <Spinner animation="border" className="loaderCircle" />
          <p>Loading</p>
        </div>
      ) : status != "pending" && status != "paid" && status != "failed" ? (
        <>
          <Header business_name={business_name} cashbackPromotion={cashbackPromotion} />
          <div className="invalidStatusCont">
            <p>{status}</p>
            {/*  */}
          </div>
          <DownloadBanner />
        </>
      ) : FromVoucher ? (
        showCaptcha ? (
          <div className="captchaModalForVoucher">
            <Modal
              isOpen={showCaptcha}
              backdrop="static"
              centered
              className="captchaModalForVoucher"
            >
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={onChange}
                badge="inline"
              />
            </Modal>
          </div>
        ) : (
          // <VoucherPhoneInput
          //   handleNextFunc={handleNextFunc}
          //   phoneNumber={phoneNumber}
          //   setPhoneNumber={setPhoneNumber}
          //   setErrors={setErrors}
          //   errors={errors}
          //   setMethod={setMethod}
          //   method={method}
          //   setOtpLoad={setOtpLoad}
          //   otpLoad={otpLoad}
          //   setVoucherData={setVoucherData}
          //   voucherData={voucherData}
          //   otpRequestLoad={otpRequestLoad}
          // />
          <VoucherCodeScreen
            documentID={documentID}
            setDocumentID={setDocumentID}
            unique_code={unique_code}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setVoucherData={setVoucherData}
            voucherData={voucherData}
          />
        )
      ) : (
        <div className={cashbackPromotion ? "" : "EmptyHeader"}>
          <Header
            business_name={businessName}
            cashbackPromotion={cashbackPromotion}
          />
          <section className="BodyContent accountinCOnt">
            <Container>
              <Row>
                <Col sm={12} md={{ size: 11, offset: 1 }}>
                  <div
                    className={
                      I18n.languageType == "ur"
                        ? "paymetClientMainHeading paymetClientMainHeadingUrdu"
                        : "paymetClientMainHeading"
                    }
                  >
                    <h3>
                      {I18n.bill_from} {businessName}
                    </h3>
                    <h5>Ph# {merchantPhone}</h5>
                  </div>
                </Col>
                <Col sm={12} md={{ size: 5, offset: 1 }}>
                  <div
                    className={
                      I18n.languageType == "ur"
                        ? "businessNamePrice businessNamePriceUrdu"
                        : "businessNamePrice"
                    }
                  >
                    {I18n.languageType == "ur" ? (
                      <h5 dir="ltr">
                        <span dir="auto">
                          {formateDate_dd_month(
                            new Date().getTime() + 86400000,
                            I18n
                          )}
                          {I18n.pay_with_bank_earn_bonus}{" "}
                        </span>
                      </h5>
                    ) : (
                      <h5>
                        {I18n.pay_with_bank}{" "}
                        <span>
                          {formateDate_dd_month(
                            new Date().getTime() + 86400000,
                            I18n
                          )}
                        </span>
                        {I18n.earn_bonus}
                      </h5>
                    )}
                    <h3>Rs. {formatNum(payable || 0)}</h3>
                    <div
                      className={
                        I18n.languageType == "ur"
                          ? "dateCont dateContUrdu"
                          : "dateCont"
                      }
                    >
                      {I18n.languageType == "ur" ? (
                        <p>
                          <span>
                            {formateDate_dd_month_yy(new Date())} <b>|</b>{" "}
                            {formatAMPM(new Date())}
                          </span>
                          <span>: {I18n.bill_Date}</span>
                        </p>
                      ) : (
                        <p>
                          <span>{I18n.bill_Date}: </span>
                          <span>
                            {formateDate_dd_month_yy(new Date())} <b>|</b>{" "}
                            {formatAMPM(new Date())}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>

                  {/* <div className="BodyContentTop">
                    <div className="totalHeading"></div>
                    <div className="totalAmount">
                      <p>
                        <span>Total Amount</span>
                        <span>Rs. {formatNum(payable)}</span>
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} md={{ size: 5, offset: 1 }}>
                    <div className="businessNamePrice">
                      <h5>Pay {businessName}</h5>
                      <h3>Rs. {formatNum(payable)}</h3>
                    </div>
                  </div> */}
                </Col>

                <Col sm={12} md={{ size: 5 }}>
                  {showSuccess ? (
                    <SuccessScreen
                      business_name={businessName}
                      accountNumber={accountNumber}
                      customer_name={customer_name}
                      refrenceId={refrenceId}
                      cashbackPercentage={cashbackPercentage}
                      cashbackPromotion={cashbackPromotion}
                      unique_code={unique_code}
                      payable={payable}
                    />
                  ) : showOtp ? (
                    <VerificationScreen
                      toggleverificationScreen={toggleverificationScreen}
                      setShowResendCode={setShowResendCode}
                      showResendCode={showResendCode}
                      setInvalidCode={setInvalidCode}
                      accountNumber={accountNumber}
                      handleResendCode={handleNext}
                      verifyDeposit={verifyDeposit}
                      invalidCode={invalidCode}
                      setCodeSent={setCodeSent}
                      verifyLoad={verifyLoad}
                      codeSent={codeSent}
                      codeRef={codeRef}
                      setCode={setCode}
                      errors={errors}
                      code={code}
                    />
                  ) : showMpin ? (
                    <MpinScreen
                      toggleMpinScreen={toggleMpinScreen}
                      setrefrenceId={setrefrenceId}
                      setMpinSend={setMpinSend}
                      unique_code={unique_code}
                      mpinSend={mpinSend}
                      method={method}
                    />
                  ) : (
                    <PaymentMethod
                      cashbackPercentage={cashbackPercentage}
                      cashbackPromotion={cashbackPromotion}
                      setAccountNumber={setAccountNumber}
                      otpRequestLoad={otpRequestLoad}
                      accountNumber={accountNumber}
                      alfalahStatus={alfalahStatus}
                      business_name={businessName}
                      unique_code={unique_code}
                      handleNext={handleNext}
                      setMethod={setMethod}
                      setErrors={setErrors}
                      history={history}
                      alfalah={alfalah}
                      method={method}
                      errors={errors}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </section>

          <DownloadBanner disabled={showOtp || showMpin ? true : false} />
          <div className="captchaModal">
            <Modal
              isOpen={showCaptcha}
              backdrop="static"
              centered
              className="captchaModal"
            >
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={onChange}
                badge="inline"
              />
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
