import AppRadioButton from "../AppRadioButton";
import ParagraphText from "../ParagraphText";
import { methodList } from "./methodList";
import React from "react";
import "./style.css";
import { BankIcon, CardIcon, MasterCardIcon } from "../../../assets/icons/newicon/icon";
import { capitalizeFirstLetter } from "../../../constants";

const PayMethods = ({ paymentMethods, handlePayMethod, payMethod }) => {
  return (
    <div className="optionListMainBox">
      {paymentMethods.map((item, index) => {
        // const { icon, value, title, cardICon } = item;
        const { logo_link, name } = item;
        return (
          <div className="optionListInnerBox" key={index}>
            <AppRadioButton
              checked={Boolean(payMethod == name)}
              onChange={handlePayMethod}
              value={name}
              customValue={
                <div className="optionListbody">
                  <ParagraphText className="fontSize14 marBot5 OneLineTruncate">
                    <span className="fontWeight500 darkGrayColor">
                      {/* {icon} */}
                      {name == "card" ? (
                        <CardIcon />
                      ) : name == "ibft" ? (
                        <BankIcon />
                      ) : (
                        <img
                          className="imgResponsive"
                          src={logo_link}
                          alt={""}
                          title={""}
                        />
                      )}
                    </span>
                    <span className="fontWeight400 darkGrayColor marLeft15">
                      {name=='ibft' ? name?.toUpperCase() : capitalizeFirstLetter(name)}
                    </span>
                    {name=='card' && (
                      <span className="optionListRightBox">{<MasterCardIcon />}</span>
                    )}
                  </ParagraphText>
                </div>
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default PayMethods;
