import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import AppFloatingLableInput from "../AppFloatingLableInput";
import {
  PhoneIcon,
  TickIcon,
  UserIcon,
} from "../../../assets/icons/newicon/icon";

const ContactInfomationCard = ({
  customerName,
  customerPhone,
  payDetails,
  setPayDetails,
}) => {
  const handleNameChange = (e) => {
    setPayDetails({ ...payDetails, customer_name: e.target.value });
  };

  const handleNumberChange = (e) => {
    const newText = e.target.value?.replace(/\D/g, "");
    setPayDetails({ ...payDetails, phone_number: newText });
  };

  return (
    <div className="contactInfoWrapper">
      <Container>
        <Row>
          <Col>
            <div className="contactInfoInnerWrapper">
              <h2 className="fontSize14 fontWeight700 darkGrayColor marBot15">
                Contact information
              </h2>

              <AppFloatingLableInput
                data={{
                  title: "Name",
                  value: customerName, // Controlled value
                  onChange: handleNameChange, // Update parent state
                  prefixIcon: customerName ? <TickIcon /> : <UserIcon />,
                  disabled: !payDetails?.nameEdit,
                }}
              />
              <AppFloatingLableInput
                data={{
                  title: "Phone number",
                  maxLength: 11,
                  value: customerPhone, // Controlled value
                  onChange: handleNumberChange, // Update parent state
                  prefixIcon: customerPhone ? <TickIcon /> : <PhoneIcon />,
                  disabled: !payDetails?.phoneEdit,
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactInfomationCard;
