import React, { useEffect } from "react";
import {
  AppFooter,
  AppHeader,
  BankDetailCard,
  InnerHeader,
  ParagraphText,
} from "../../common";
import { Col, Container, Row } from "reactstrap";
import { handleResetRoute, path } from "../../../constants";
import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { link_expire } from "../../../assets/images";

const IBFT = ({ history }) => {
  const { payDetails } = useTxnsDetailContext();

  useEffect(() => {
    handleResetRoute(payDetails, history);
  }, []);

  const handleAccountDetail = (selectedAccount) => {
    history.push({
      pathname: `/${path[1]}/${path[2]}/${path[3]}/ibft-detail`,
      selectedAccount,
      consumer_number: payDetails?.consumer_number,
    });
  };
  return (
    <div>
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />{" "}
      <div className="homeBodyBox">
        <InnerHeader history={history} visible={true} />
        <Container>
          <Row>
            <Col>
              {payDetails?.connectAccounts?.length ? (
                <>
                  <h1 className="fontSize26 fontWeight700 darkGrayColor">
                    {payDetails?.store_name} udhaar book account
                  </h1>
                  <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20">
                    Payment karne ke liye Udhaar book ko money transfer karein
                  </ParagraphText>
                  {payDetails?.connectAccounts.map((account, index) => {
                    return (
                      <BankDetailCard
                        data={{
                          key: index,
                          image: account?.bank_details?.logo,
                          heading: account?.account_number || account?.iban,
                          title: account?.name,
                          paragraph: account?.display_name,
                          handleClick: () => handleAccountDetail(account),
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                <section className="NotFoundMainWrapper">
                  <div className="homeBodyBox">
                    <Container>
                      <Row>
                        <Col sm={12}>
                          <div className="NotFoundWrapper">
                            <img
                              className="notFoundImage"
                              src={link_expire}
                              alt=""
                              title=""
                            />
                            <h1 className="fontSize26 fontWeight600 darkGrayColor marTop10 marBot10 textCenter">
                              No bank accounts
                            </h1>
                            <p className="fontSize14 fontWeight400 darkGrayColor marTop10 marBot10 textCenter">
                              {payDetails?.store_name} ko bolein Share Accounts
                              option se bank add kare
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default IBFT;
