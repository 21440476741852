import { Container, Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import { LanguageContext } from "../../../context/LanguageContext";
import React, { useEffect, useState, useContext } from "react";
import tElenor from "../../../assets/images/Telenor.png";
import sCom from "../../../assets/images/SCO-Logo.png";
import uFone from "../../../assets/images/Ufone.png";
import zOng from "../../../assets/images/zong.png";
import jAzz from "../../../assets/images/Jazz.png";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";

const DURATION = 41;

const VerificationScreen = ({
  setShowResendCode,
  handleResendCode,
  showResendCode,
  setInvalidCode,
  accountNumber,
  verifyDeposit,
  OTPInterval,
  invalidCode,
  setCodeSent,
  setSeconds,
  codeSent,
  seconds,
  codeRef,
  setCode,
  code,
}) => {
  const [I18n] = useContext(LanguageContext);

  const [resendModal, setResendModal] = useState(false);
  const [resendLoad, setResendLoad] = useState(false);

  useEffect(() => {
    if (codeSent) {
      let s = seconds;

      OTPInterval.current = setInterval(() => {
        s--;

        if (s === 0) {
          clearInterval(OTPInterval.current);
          setShowResendCode(true);
          setSeconds(Number(DURATION));
          setCodeSent(false);
          return;
        }

        if (s < 10) {
          s = "0" + s;
        }

        setSeconds(s);
      }, 1000);
    }
  }, [codeSent]);

  const handleOtp = (otp) => {
    if (invalidCode) setInvalidCode(false);
    setCode(otp);
    if (otp.length == 4) {
      logEvent(analytics, "otp_entered");
      verifyDeposit({ otp });
    }
  };

  const handleResendModal = () => setResendModal(!resendModal);
  const handleResendNetworks = (e) => {
    setResendLoad(true)
    setResendModal(false);
    handleResendCode(e)
      .catch((err) => toast.error(err?.response?.data?.message || "An error occured"))
      .finally(() => setResendLoad(false))

  };

  return (
    <>
      <h1 className="fontSize26 fontWeight700 darkGrayColor">Verify OTP</h1>
      <div className="BodyContentTop paymentFieldSection verificationBox">
        <div className="verficationBottomSection">
          <div className="verificationInputMainCont">
            <p className=" darkGrayTwoColor">{`Apne ${accountNumber} pe call attend kar ke OTP code verify karein `}</p>
            <div className="verificationInputInnerMain">
              <OtpInput
                shouldAutoFocus={true}
                containerStyle={{ width: "100%", }}
                inputStyle={{ backgroundColor: 'white', }}
                className="verificationInputInnerCont"
                value={code}
                onChange={handleOtp}
                numInputs={4}
                isInputNum={true}
                ref={(e) => (codeRef.current = e)}
              />
            </div>

            <p className="errorMsgInvalidCode">
              {invalidCode ? I18n.invalid_code : null}
            </p>
          </div>

          {showResendCode === false ? (
            <div className="waitingForCodeNumber">
              <p className="waitingCodeTex">
                {`${I18n.sms_wait_time}: 00:${seconds > 9 ? seconds : seconds}`}
              </p>
              <div className="waitingLoad">
                <Spinner animation="border" className="loaderCircle" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {showResendCode == true && (
        <div className="verifyCodeAction">
          <div className="waitingForCode">
            <p
              className={
                I18n.languageType == "ur"
                  ? "waitingCodeTex waitingCodeTexUrdu"
                  : "waitingCodeTex"
              }
            >
              {I18n.kia_code_nhi_mila_abhi_resend_code}
            </p>
          </div>
          <div className="verifyCodeActionBottom">
            <div className="verificationActionSectiion">
              <button
                type="submit"
                className={
                  I18n.languageType == "ur"
                    ? "verification_btn verification_btnUrdu"
                    : "verification_btn"
                }
                onClick={handleResendModal}
                disabled={resendLoad}
              >
                {resendLoad ? <Spinner />
                  :
                  <React.Fragment>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        alt="Toko Logo"
                      >
                        <path
                          fill="#4ac600"
                          d="M16.833,3.198H3.677c-1.161,0-2.104,0.943-2.104,2.104v8.161C1.572,14.021,1.8,14.566,2.198,14.96
                                      c0.395,0.394,0.922,0.608,1.499,0.608l0,0l10.491,0.01c0.156,0,0.309,0.048,0.434,0.137l2.475,1.792
                                      c0.199,0.146,0.436,0.224,0.68,0.224l0,0c0.373,0,0.725-0.182,0.941-0.48c0.143-0.197,0.223-0.439,0.223-0.684V5.303
                                      C18.938,4.142,17.992,3.198,16.833,3.198z M2.953,5.303c0-0.399,0.325-0.724,0.724-0.724h13.156c0.399,0,0.726,0.325,0.726,0.724
                                      v10.844l-2.142-1.545c-0.36-0.262-0.787-0.399-1.24-0.399L3.688,14.189H3.681c-0.194,0-0.376-0.074-0.513-0.211
                                      c-0.136-0.136-0.215-0.322-0.215-0.516V5.303z M6.404,7.965c0.802,0,1.452,0.65,1.452,1.452s-0.65,1.452-1.452,1.452
                                      c-0.802,0-1.452-0.65-1.452-1.452S5.602,7.965,6.404,7.965z M10.251,7.965c0.802,0,1.452,0.65,1.452,1.452s-0.65,1.452-1.452,1.452
                                      s-1.452-0.65-1.452-1.452S9.449,7.965,10.251,7.965z M14.09,10.869c0.73,0,1.337-0.543,1.438-1.246l0.033-0.003l-0.002-0.221
                                      c-0.012-0.791-0.662-1.435-1.469-1.435c-0.8,0-1.451,0.652-1.451,1.452C12.639,10.218,13.29,10.869,14.09,10.869z"
                        />
                      </svg>
                    </span>
                    <span>{I18n.resend_code}</span>
                  </React.Fragment>
                }
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        backdrop={true}
        toggle={() => setResendModal(false)}
        isOpen={resendModal}
        centered
        className="selectNetworModal"
      >
        <ModalHeader>
          <p className="headerleftSide">{I18n.apne_mobile_networl_change}</p>
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <div className="modalBodyContent">
              <div className="col-sm-12">
                <p className="modaltelecosDescription">
                  {I18n.apna_current_network_select_karein}
                </p>
              </div>

              <div className="row justify-content-md-center">
                <div className="modalTelcosMain">
                  <button
                    className="modalBodyButton"
                    onClick={(e) => handleResendNetworks(e, "Jazz")}
                  >
                    <img src={jAzz} alt="jazz" title="Jazz" />
                  </button>
                  <button
                    className="modalBodyButton"
                    onClick={(e) => handleResendNetworks(e, "Telenor")}
                  >
                    <img src={tElenor} alt="Telenor" title="Telenor" />
                  </button>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="modalTelcosMain">
                  <button
                    className="modalBodyButton"
                    onClick={(e) => handleResendNetworks(e, "Ufone")}
                  >
                    <img src={uFone} alt="Ufone" title="ufone" />
                  </button>
                  <button
                    className="modalBodyButton"
                    onClick={(e) => handleResendNetworks(e, "Zong")}
                  >
                    <img src={zOng} alt="Zong" title="Zong" />
                  </button>
                </div>
              </div>

              <div className="row justify-content-md-center">
                <div className="modalTelcosMain">
                  <button
                    className="modalBodyButton"
                    onClick={(e) => handleResendNetworks(e, "Ufone")}
                  >
                    <img src={sCom} alt="scom" title="scom" />
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default VerificationScreen;
