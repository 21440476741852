import Icon from "../../../assets/Icons";
import React from "react";

const BankList = ({
  handleSelectBank,
  selectedBank,
  banks,
}) => {

  let { title, data = [] } = banks;

  return (
    <div className="detailScreenCardMain">
      <p>
        <span className="fontSize14 fontWeight500 darkGrayColor">
          {title}
        </span>
      </p>
      {data?.map((item, index) => {
        const { logo_link, display_name, logo } = item;
        return (
          <div
            onClick={() => handleSelectBank(item)}
            className="detailScreenCardInner"
            key={index}
          >
            <div className="detailScreenCard">
              <div className="detailScreenCardLeft">
                <img className="UdhaarLogo" src={logo_link || logo} alt="" title="" />
              </div>

              <div className="detailScreenCardRight">
                <p>
                  <span className="fontSize14 fontWeight500 darkGrayColor">
                    {display_name}
                  </span>
                </p>

                {selectedBank?.display_name == display_name ? (
                  <Icon
                    data={{
                      viewBox: "0 0 12 9",
                      name: "checkIcon",
                      fill: "#4AC600",
                      height: "14",
                      width: "17",
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BankList;
