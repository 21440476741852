import mpinProgress from "../../../assets/images/mpinProgress.png";
import { LanguageContext } from "../../../context/LanguageContext";
import React, { useEffect, useState, useContext } from "react";
import { ParagraphText, PrimaryButton } from "../../common";
import { getDepositDetails } from "../../../SyncServices";
import { logEvent } from "@firebase/analytics";
import { analytics } from "../../../firebase";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import "./style.css";

const DURATION = "120";

const MpinScreen = ({
  toggleMpinScreen,
  setReferenceId,
  setPayDetails,
  setMpinSend,
  payDetails,
  mpinSend,
  method,
}) => {
  const [I18n] = useContext(LanguageContext);

  const [resendMpin, setResendMpin] = useState(false);
  const [seconds, setSeconds] = useState(DURATION);

  let timerIntrv;
  let apiIntrv;

  useEffect(() => {
    if (mpinSend) {
      handleVerifyMPIN();
      let s = seconds;

      timerIntrv = setInterval(() => {
        s--;

        if (s === 0) {
          setSeconds(Number(DURATION));
          clearInterval(apiIntrv);
          clearInterval(timerIntrv);
          setResendMpin(true);
          setMpinSend(false);
          return;
        }

        if (s < 10) {
          s = "0" + s;
        }

        setSeconds(s);
      }, 1000);
    }
  }, [mpinSend]);

  const handleVerifyMPIN = () => {
    try {
      if (navigator.onLine) {
        apiIntrv = setInterval(() => {
          getDepositDetails({ unique_code: payDetails?.unique_code }).then((res) => {
            const { message, payment_status, reference_id } = res;

            setPayDetails({ ...res, ...payDetails });

            if (payment_status === "paid" || payment_status == "failed") {
              clearInterval(apiIntrv);
              clearInterval(timerIntrv);

              toggleMpinScreen(Boolean(payment_status === "paid"));

              if (payment_status === "paid") {
                logEvent(analytics, "payment_successful", {});
                setReferenceId(reference_id);
              } else toast.error(message || "An error occured");

            }
          }).catch((err) => {
            toggleMpinScreen();
            toast.error(
              err?.response?.status == 503
                ? "Service not available. Please try again later."
                : err.response?.data?.message
            );

            clearInterval(apiIntrv);
            clearInterval(timerIntrv);
          });
        }, 5000);
      }
    } catch (e) { }
  };

  const handleResend = () => {
    setResendMpin(false);
    setMpinSend(true);
  };

  return (
    <>
      <h1 className="fontSize26 fontWeight700 darkGrayColor textCenter marBot15">
        {resendMpin ? I18n.mpin_failed : I18n.verify_mpin}
      </h1>
      <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20 textCenter">
        {resendMpin ? null : <span>{method == "jazzcash" ? I18n.JazzCash : I18n.EasyPaisa}</span>}
        <span className="marLeft5">{resendMpin ? I18n.can_not_verify_mpin : I18n.verify_mpin_desc} </span>
      </ParagraphText>
      {resendMpin ? null :
        <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20 textCenter">
          <span>
            {Math.floor(seconds / 60) == 0
              ? "00"
              : "0" + Math.floor(seconds / 60)}
            :
            {seconds - Math.floor(seconds / 60) * 60 < 10
              ? "0" + (seconds - Math.floor(seconds / 60) * 60)
              : seconds - Math.floor(seconds / 60) * 60}
          </span>
          <span className="waitingLoad textCenter marBot20">
            <Spinner animation="border" className="loaderCircle" />
          </span>
        </ParagraphText>}

      <div className="BodyContentTop paymentFieldSection mpinScreen">
        <div className="mPinText mPinImageBox">
          <img
            className="mpinScreenImage"
            src={mpinProgress}
            title="mPin Image"
            alt="mPin Imag"
          />
        </div>

        <div className="trustedBusiness mPinTrustBox">
          <ParagraphText className="fontSize14 darkGrayColor">
            <span>
              <i className="safesecurIcon"></i>
            </span>
            <span>3.4M+ businesses ka trust</span>
          </ParagraphText>
        </div>
      </div>

      {resendMpin ?
        <div className="inputBoxbutton">
          <PrimaryButton
            data={{
              className: `width40 fontSize16 fontWeight600 textUppercase buttonStyle BtnGreenBg whiteColor`,
              onClick: handleResend,
              title: "Request MPIN",
              loading: false,
              type: "submit",
            }}
          />
        </div>
        : null}

    </>
  );
};

export default MpinScreen;
