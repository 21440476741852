import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { handleResetRoute, path } from "../../../constants";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BankList from "../../common/BankList";
import "./style.css";
import {
  AppFloatingLableInput,
  ParagraphText,
  InnerHeader,
  AppHeader,
} from "../../common";

const OneBill = ({ history }) => {
  const { payDetails } = useTxnsDetailContext();

  const [bankList, setBankList] = useState(payDetails?.banks || []);
  const [selectedBank, setSelectedBank] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    handleResetRoute(payDetails, history);
    handleList();
  }, []);

  const handleList = () => {
    let list = groupItemsForSectionList(payDetails?.banks || []);
    setBankList(list);
  };

  const handleSelectBank = (bankDetail) => {
    setSelectedBank(bankDetail);
    history.push({
      pathname: `/${path[1]}/${path[2]}/${path[3]}/one-bill-detail`,
      consumer_number: payDetails?.consumer_number,
      selectedBank: bankDetail,
    });
  };

  const groupItemsForSectionList = (items) => {
    let grouped = {};
    let wallets = [];
    let popular = [];

    items.forEach((item) => {
      if (item.display_name) {
        const isWallet =
          item?.display_name?.toLowerCase() == "jazzcash" ||
          item?.display_name?.toLowerCase() == "easypaisa";

        const key =
          item?.display_name?.length > 0 && item.display_name[0]?.toUpperCase();

        if (isWallet) {
          wallets.push(item);
        } else {
          if (!grouped[key]) {
            grouped[key] = [];
          }
          grouped[key].push(item);
        }
      }
    });

    if (wallets.length > 0) {
      popular.push({
        title: "Popular",
        data: wallets,
      });
    }

    const sections = Object.keys(grouped)
      .sort()
      .map((key) => ({
        title: key,
        data: grouped[key],
      }));

    return [...popular, ...sections];
  };

  const handleChange = (e) => {
    let val = e.target.value;
    setSearchText(val);

    const filteredBanks = payDetails?.banks?.filter((x) =>
      x?.display_name?.toLowerCase().includes(val?.toLowerCase())
    );

    const sectionedData = groupItemsForSectionList(filteredBanks);
    setBankList(sectionedData);
  };

  return (
    <div>
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />{" "}
      <div className="homeBodyBox">
        <InnerHeader history={history} visible={true} />
        <Container>
          <Row>
            <Col>
              <h1 className="fontSize26 fontWeight700 darkGrayColor">
                Select bank
              </h1>
              <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20">
                1 bill payment ke leye apna bank select karein
              </ParagraphText>
              <div className="selectBankBox">
                <AppFloatingLableInput
                  data={{
                    title: "Search bank",
                    value: searchText,
                    onChange: handleChange,
                  }}
                />
              </div>
              <div className="bankListBoxWrapper">
                {/* <h2 className="fontSize14 fontWeight700 darkGrayColor marBot10">
                  Payment method
                </h2> */}
                {Object.values(bankList)?.length
                  ? Object.values(bankList)?.map((item) => (
                    <BankList
                      banks={item}
                      selectedBank={selectedBank}
                      handleSelectBank={handleSelectBank}
                    />
                  ))
                  : "Koi bank nahi mila"}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OneBill;
