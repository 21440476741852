import React from "react";
import ParagraphText from "../ParagraphText";
import "./style.css";

const BankDetailCard = ({ data }) => {
  const { key, icon, image, heading, title, paragraph, handleClick, handleCopy } = data;
  return (
    <div key={key} className="bankDetailBoxWrapper" onClick={handleClick}>
      
      <div className="bankDetailBoxInnerLeft"> {image ? <img className="UdhaarLogo" src={image} alt="" title="" /> : icon}</div>
      <div className="bankDetailBoxInnerRight">
        <ParagraphText className="fontSize14 fontWeight500 darkGrayColor">
          {heading}
        </ParagraphText>
        <ParagraphText className="fontSize10 fontWeight500 darkGrayTwoColor">
          {title}
        </ParagraphText>
        <ParagraphText className="fontSize10 fontWeight400 darkGrayTwoColor">
          {paragraph}
        </ParagraphText>
        {handleCopy && (
          <div className="copyTag" onClick={handleCopy}>
            <ParagraphText className="fontSize10 darkGrayColor">
              COPY
            </ParagraphText>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankDetailCard;
