import { RECAPTCHA_SITE_KEY, handleResetRoute, path } from "../../../constants";
import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import React, { useState, useRef, useContext, useEffect } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { Container, Modal, Row, Col } from "reactstrap";
import VerificationScreen from "../VerificationScreen";
import { AppHeader, InnerHeader } from "../../common";
import ReCAPTCHA from "react-google-recaptcha";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import SuccessScreen from "../SuccessScreen";
import PaymentMethod from "../PaymentMethod";
import NewLoader from "../LoadingScreen";
import MpinScreen from "../MpinScreen";
import { toast } from "react-toastify";
import "./style.css";
import {
  verifyOtpSession,
  verifyOtpCodeV2,
  getConsumerID,
} from "../../../SyncServices";

const DURATION = 41;

const VerifyWallet = ({ history }) => {
  const { payMethod } = history.location || {};

  const { payDetails, setPayDetails, getTxnsDetails } = useTxnsDetailContext();
  const [I18n] = useContext(LanguageContext);

  const OTPInterval = useRef(null);
  const codeRef = useRef();

  const [otpRequestLoad, setOtpRequestLoad] = useState(false);
  const [showResendCode, setShowResendCode] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successLoad, setSuccessLoad] = useState(false);
  const [verifyLoad, setVerifyLoad] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [seconds, setSeconds] = useState(DURATION);
  const [documentID, setDocumentID] = useState("");
  const [showMpin, setShowMpin] = useState(false);
  const [mpinSend, setMpinSend] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [errors, setErrors] = useState({});
  const [code, setCode] = useState("");


  useEffect(() => {
    handleResetRoute(payDetails, history);
  }, []);

  const toggleMpinScreen = (success = false) => {
    setShowMpin(false);
    setMpinSend(false);

    if (success) setShowSuccess(true);
  };

  const handleCodeFailed = () => {
    codeRef.current.focusInput(0);
    setShowResendCode(true);
    setInvalidCode(true);
    setSeconds(DURATION);
    setCodeSent(false);
    setCode("");
  };

  const handleShowOTP = () => {
    setSeconds(DURATION);
    setShowResendCode(false);
    setInvalidCode(false);
    setCode("");
    setCodeSent(true);
    setShowOtp(true);
    codeRef?.current?.focusInput(0);
  };

  const handleShowMPIN = () => {
    setShowMpin(true);
    setMpinSend(true);

    setShowOtp(false);
    setCodeSent(false);
    setShowCaptcha(false);
    setShowResendCode(false);
    setCode("");
  };

  const handleNext = () => {
    let patt = /03[0-9]{2}(?!1234567)(?!1111111)(?!7654321)[0-9]{7}/; // VALIDATION FOR PAKISTANI MOBILE NUMBER

    if (accountNumber.length != 11 || accountNumber.match(patt) == null) {
      setErrors({
        ...errors,
        accountNumber: I18n.phone_number_format,
      });
      return;
    } else {
      setOtpRequestLoad(true);

      let verifyParams = {
        customer_phone: accountNumber?.toString(),
        unique_code: payDetails?.unique_code,
        payment_process: "online_payment",
        telco_uid: accountNumber,
      };

      verifyOtpCodeV2(verifyParams).then((res) => {
        const { document_id, captcha, otp_sent } = res;

        if (document_id) {
          setDocumentID(document_id);

          if (captcha) setShowCaptcha(true);
          else if (otp_sent) handleShowOTP();
        }
      }).catch((err) => toast.error(err?.response?.data?.message || "An error occured"))
        .finally(() => setOtpRequestLoad(false));
    }
  };

  const handleResendCode = () => {
    return new Promise((resolve, reject) => {
      let verifyParams = {
        customer_phone: accountNumber?.toString(),
        unique_code: payDetails?.unique_code,
        payment_process: "online_payment",
        telco_uid: accountNumber,
      };

      verifyOtpCodeV2(verifyParams).then((res) => {
        res?.document_id && setDocumentID(res.document_id);
        handleShowOTP();
        resolve();
      }).catch((err) => reject(err))
    })
  };

  const verifyDeposit = (params) => {
    if (showCaptcha) setShowCaptcha(false);

    setVerifyLoad(true);

    let paramsOfAPI = {
      customer_phone: payDetails?.phone_number?.toString(),
      deposited_by: accountNumber?.toString(),
      unique_code: payDetails?.unique_code,
      payment_process: "online_payment",
      document_id: documentID,
      ...params
    };

    verifyOtpSession(paramsOfAPI).then((res) => {
      const { otp_sent, verified } = res || {};

      if (verified) proceedToPay();
      else if (otp_sent) {
        setVerifyLoad(false);
        handleShowOTP();
      }

      logEvent(
        analytics,
        verified
          ? "payment_verified"
          : otp_sent && "otp_sent"
      );

    }).catch((err) => {
      if (showOtp) handleCodeFailed();
      else setErrors({ ...errors, captcha: err.response.data?.message });

      setVerifyLoad(false);
    }).finally(() => clearInterval(OTPInterval.current))
  };

  const proceedToPay = () => {
    let params = {
      customer_phone: payDetails?.phone_number?.toString(),
      customer_name: payDetails?.customer_name,
      deposited_by: accountNumber?.toString(),
      unique_code: payDetails?.unique_code,
      amount: Number(payDetails?.amount),
      business_report_url: path[2],
      document_id: documentID,
      invoice_id: path[3],
      partner: payMethod,
    };

    getConsumerID(params).then((res) => {
      if (res?.success) {
        handleShowMPIN();
        setVerifyLoad(false);
      }

      toast.success(res.message || "Deposit request placed");
    }).catch((err) => {

      if (showOtp) setShowOtp(false);
      if (codeSent) setCodeSent(false);
      if (showCaptcha) setShowCaptcha(false);
      if (code) setCode("");

      setVerifyLoad(false);
      toast.error(err?.response?.data?.message || "An error occured");

      logEvent(analytics, "payment_failed", {
        error: err.response.data?.message || "unrecognised_error",
      });
    });
  };

  const handleDone = async () => {
    setSuccessLoad(true);
    await getTxnsDetails();
    history.replace(`/${path[1]}/${path[2]}/${path[3]}/receipt`);
    setSuccessLoad(false);
  };

  const handleShare = async () => {
    try {
      const shareData = {
        title: "Transaction successful",
        text: "Transaction successful",
      };

      if (navigator.canShare) {
        await navigator.share(shareData);
        console.log("Image shared successfully");
      } else {
        console.warn("Sharing not supported for this file.");
      }
    } catch (error) {
      alert("Error sharing image:");
    }
  };

  return (
    <div className="h-100">
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />{" "}
      <div className="homeBodyBox">
        <InnerHeader
          visible={!showSuccess && !codeSent && !mpinSend}
          history={history}
        />

        <Container>
          <Row>
            <Col sm={12}>
              {showSuccess ? (
                <SuccessScreen
                  accountNumber={accountNumber}
                  referenceId={referenceId}
                  handleShare={handleShare}
                  successLoad={successLoad}
                  handleDone={handleDone}
                  payDetails={payDetails}
                  payMethod={payMethod}
                />
              ) :
                showOtp ? (
                  <VerificationScreen
                    setShowResendCode={setShowResendCode}
                    handleResendCode={handleResendCode}
                    showResendCode={showResendCode}
                    setInvalidCode={setInvalidCode}
                    accountNumber={accountNumber}
                    verifyDeposit={verifyDeposit}
                    invalidCode={invalidCode}
                    setCodeSent={setCodeSent}
                    OTPInterval={OTPInterval}
                    setSeconds={setSeconds}
                    codeSent={codeSent}
                    codeRef={codeRef}
                    setCode={setCode}
                    seconds={seconds}
                    errors={errors}
                    code={code}
                  />
                ) : showMpin ? (
                  <MpinScreen
                    toggleMpinScreen={toggleMpinScreen}
                    setReferenceId={setReferenceId}
                    setPayDetails={setPayDetails}
                    setMpinSend={setMpinSend}
                    payDetails={payDetails}
                    mpinSend={mpinSend}
                    method={payMethod}
                  />
                ) : (
                  <PaymentMethod
                    setAccountNumber={setAccountNumber}
                    otpRequestLoad={otpRequestLoad}
                    accountNumber={accountNumber}
                    payDetails={payDetails}
                    handleNext={handleNext}
                    setErrors={setErrors}
                    method={payMethod}
                    errors={errors}
                  />
                )}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="captchaModal">
        <Modal
          className="captchaModal"
          isOpen={showCaptcha}
          backdrop="static"
          centered
        >
          <ReCAPTCHA
            onChange={(token) => verifyDeposit({ 'g-recaptcha-response': token })}
            sitekey={"6Le_BlkcAAAAAHbikcxYqjooYvfsiD5PZ22O6FZq"}
            badge="inline"
          />
        </Modal>
      </div>

      {verifyLoad ? <NewLoader /> : null}

    </div>
  );
};

export default VerifyWallet;
