import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { handleResetRoute } from "../../../constants";
import Accordion from "react-bootstrap/Accordion";
import { Container, Row, Col } from "reactstrap";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "./style.css";
import {
  EasypaisaIcon,
  YoutubeIcon,
  CopyIcon,
  DownIcon,
} from "../../../assets/icons/newicon/icon";
import {
  ParagraphText,
  PrimaryButton,
  DeatailCard,
  InnerHeader,
  AppHeader,
} from "../../common";

const EasyPaisaApp = ({ history }) => {
  const { payDetails } = useTxnsDetailContext();

  useEffect(() => {
    handleResetRoute(payDetails, history);
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(payDetails?.consumer_number);
      toast.success("Copied");
    } catch (error) { }
  };

  const actions = [
    { icon: <CopyIcon />, label: "Copy", handleClick: handleCopy },
  ];

  const handleBankVideo = () => {
    if (payDetails?.tutorial_link_easypaisa_collection)
      window.open(
        `https://www.youtube.com/shorts/${payDetails?.tutorial_link_easypaisa_collection}`
      );
    else toast.error("Video not available");
  };

  return (
    <div>
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />
      <div className="homeBodyBox">
        <InnerHeader history={history} visible={true} />
        <Container>
          <Row>
            <Col>
              <h1 className="fontSize26 fontWeight700 darkGrayColor">
                Follow steps
              </h1>
              <DeatailCard
                data={{
                  icon: <EasypaisaIcon />,
                  heading: payDetails?.consumer_number || "",
                  title: "Consumer ID",
                  paragraph: "Easypaisa",
                  actions: actions,
                }}
              />
              <div className="easypaisaAppStepsBox">
                <Accordion className="itemsListAccordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="receiptBoxItem">
                        <ParagraphText className="receiptBoxItemLeft">
                          <span className="fontSize18 fontWeight700 darkGrayColor marBot5">
                            See steps
                          </span>
                          <span className="fontSize12 fontWeight400 darkGrayTwoColor">
                            Pay with Easypaisa App, simple and fast.
                          </span>
                        </ParagraphText>
                        <ParagraphText className="receiptBoxItemRight">
                          <YoutubeIcon onClick={handleBankVideo} />
                          <DownIcon />
                        </ParagraphText>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {payDetails?.steps?.length
                        ? payDetails?.steps.map((item, index) => {
                          return (
                            <div
                              className="easypaisaAppStepsBoxList"
                              key={index}
                            >
                              <ParagraphText className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                                {`Step ${index + 1}`}
                              </ParagraphText>
                              <ParagraphText className="fontSize12 fontWeight500 darkGrayColor">
                                {item}
                              </ParagraphText>
                            </div>
                          );
                        })
                        : null}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="homeActionBox">
          <PrimaryButton
            data={{
              className: "width40 fontSize16 fontWeight600 textUppercase buttonStyle BtnGreenBg whiteColor",
              title: "Watch Tutorial Video",
              onClick: handleBankVideo,
              type: "submit",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EasyPaisaApp;
