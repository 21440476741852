import React from "react";
import "./style.css";
import { ParagraphText } from "../../common";
import { LoaderIcon } from "../../../assets/icons/newicon/icon";

const NewLoader = () => {
  return (
    <div className="newLoaderBox">
      <ParagraphText>
        <LoaderIcon />
      </ParagraphText>
      <ParagraphText className="fontSize14 whiteColor marTop20">
        Loading...
      </ParagraphText>
    </div>
  );
};

export default NewLoader;
