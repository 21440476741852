export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.oscarudhaarapp";
export const APP_STORE_LINK =
  "https://apps.apple.com/pk/app/udhaar-app-digital-khatabook/id1542333203";
export const RECAPTCHA_SITE_KEY = "6Le_BlkcAAAAAHbikcxYqjooYvfsiD5PZ22O6FZq";
export const CHATWOOT_BASE_URl = "https://app.chatwoot.com";
export const CHATWOOT_TOKEN = "i4VCnZxPD4XPg7FgT1uX8sML";
export const CHATWOOT_KEY = "wNXpR4yPyAvajZZW5s7CFzmR";

export const REPORT_URL = "https://payments-stage.oscar.pk";
// export const REPORT_URL = "https://pay.udhaar.pk"; //CHANGE THIS FOR PROD

// export const RECAPTCHA_SITE_KEY = '6Lc5ND4cAAAAAGXoEv1eHMeQlNFxP15GgY9IMQ7Q';

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

const setBaseURL = () => {
  let url = window.location.href.split("/")[2];

  if (
    url.includes("localhost") ||
    url.includes("192.") ||
    url.includes("payments-stage") ||
    url.includes("172.")
  ) {
    return "https://udhaar-api-stage.oscar.pk";
    // return "https://payments-stage.oscar.pk";
  } else {
    return `https://${url}`;
  }
};

const getBankUrl = () => {
  let url = window.location.href.split("/")[2];

  if (
    url.includes("localhost") ||
    url.includes("192.") ||
    url.includes("payments-stage") ||
    url.includes("172.")
  ) {
    // return `https://payments.bankalfalah.com/SSO/SSO/SSO`
    return "https://sandbox.bankalfalah.com/SSO/SSO/SSO";
  } else {
    return `https://payments.bankalfalah.com/SSO/SSO/SSO`;
  }
};

const getReqUrl = () => {
  return window.location.pathname.split("/")[2];
};

const getUniqueCode = () => {
  const unique_code = window.location.pathname.split("/");

  if (unique_code[1] === "c") return unique_code[2];
  else return unique_code[1];
};

const checkVoucherUrl = () => {
  const unique_code = window.location.pathname.split("/");

  if (unique_code[1] === "c") return true;
  else return false;
};

const getPath = () => {
  return window.location.pathname.split("/");
};

export const unique_code = getUniqueCode();
export const FromVoucher = checkVoucherUrl();
export const path = getPath();

export const BASE_URL = setBaseURL();
export const BANK_URL = getBankUrl();
export const REQ_URL = getReqUrl();

export const formateDate_dd_month = (date, I18n) => {
  date = new Date(date);

  let current_datetime = new Date(date);
  let formatted_date =
    current_datetime.getDate() + " " + I18n.months[current_datetime.getMonth()];
  return formatted_date;
};

export const getBackupDate = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateString = `${date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()} | ${formatAMPM(date)}`;
  return dateString;
};

export const formate_month_yy = (date) => {
  date = new Date(date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let current_datetime = new Date(date);
  let formatted_date =
    current_datetime.getDate() +
    " " +
    months[current_datetime.getMonth()] +
    " " +
    current_datetime.getFullYear();
  return formatted_date;
};

export const formateDate_dd_month_yy = (date) => {
  date = new Date(date);
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let current_datetime = new Date(date);
  let formatted_date =
    current_datetime.getDate() +
    " " +
    months[current_datetime.getMonth()] +
    " " +
    current_datetime.getFullYear();
  return formatted_date;
};

export var formatAMPM = (date) => {
  date = new Date(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatNum = (num) => {
  return num
    ? Number(num)
        .toFixed(2)
        .replace(/[.,]00$/, "")
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const handleResetRoute = (payDetails, history) => {
  if (!payDetails?.invoice_id)
    history.replace(`/${path[1]}/${path[2]}/${path[3]}`);
};
