import { Col, Container, Row } from "react-bootstrap";
import Icon from "../../../assets/Icons";
import ParagraphText from "../ParagraphText";
import { Logo } from "../../../assets/icons/newicon/icon";
import "./style.css";

const AppFooter = () => {
  return (
    <div className="newFooter">
      <Container>
        <Row>
          <Col>
            <div className="newFooterInner">
              <ParagraphText className="marBot0 fontSize10 mediumGrayColor">
                <span>Powered by</span>
                <span className="marLeft5">
                  <Logo />
                </span>
              </ParagraphText>
              <ul className="footerLinks">
                <li>
                  <a href="https://udhaar.pk/terms-of-use/" target="_blank">Terms</a>
                </li>
                <li>
                  <a href="https://udhaar.pk/privacy-policy/" target="_blank">Privacy</a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppFooter;
