import React, { useEffect } from "react";
import { AppHeader, DeatailCard, InnerHeader } from "../../common";
import { Col, Container, Row } from "reactstrap";
import {
  CopyIcon,
  FaysalBankIcon,
  SaveIcon,
  ShareIcon,
} from "../../../assets/icons/newicon/icon";
import { toast } from "react-toastify";
import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { handleResetRoute } from "../../../constants";

const AccountDetail = ({ history }) => {
  const { selectedAccount } = history.location || {};
  const { name, account_number, iban, display_name, bank_details } =
    selectedAccount || {};

  const { payDetails } = useTxnsDetailContext();

  useEffect(() => {
    handleResetRoute(payDetails, history);
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(account_number || iban);
      toast.success("Copied");
    } catch (error) {}
  };

  const actions = [
    // { icon: <SaveIcon />, label: "Save", handleClick: "" },
    // { icon: <ShareIcon />, label: "Share", handleClick: "" },
    { icon: <CopyIcon />, label: "Copy", handleClick: handleCopy },
  ];
  return (
    <div>
      <AppHeader
        data={{
          title: payDetails?.store_name,
        }}
      />{" "}
      <div className="homeBodyBox">
        <InnerHeader history={history} visible={true} />
        <Container>
          <Row>
            <Col>
              <h1 className="fontSize26 fontWeight700 darkGrayColor">
                Account details
              </h1>
              <DeatailCard
                data={{
                  image: bank_details?.logo,
                  heading: account_number || iban,
                  title: name,
                  paragraph: display_name,
                  actions: actions,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AccountDetail;
