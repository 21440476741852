import { LanguageContext } from "../../../context/LanguageContext";
import { OneBillIcon } from "../../../assets/icons/newicon/icon";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import "../OneBill/style.css";
import {
    BankDetailCard,
    ParagraphText,
    PrimaryButton,
    InnerHeader,
} from "../../common";

const BankDetail = ({ history }) => {
    const { consumer_number, selectedBank } = history.location || {};
    const { display_name, tutorial_link_1bill } = selectedBank || {};

    const [I18n] = useContext(LanguageContext);

    useEffect(() => {
        if (!selectedBank) history.goBack();
    }, [])

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(consumer_number);
            toast.success("Copied");
        } catch (error) { }
    };

    const handleBankVideo = () => {
        if (tutorial_link_1bill) window.open(`https://www.youtube.com/shorts/${tutorial_link_1bill}`)
        else toast.error("Video not available")
    };

    return (
        <div>
            <div className="homeBodyBox">
                <InnerHeader history={history} visible={true} />
                <Container>
                    <Row>
                        <Col>

                            <h1 className="fontSize26 fontWeight700 darkGrayColor">
                                {display_name}
                            </h1>

                            <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20">
                                Instruction for 1Bill
                            </ParagraphText>

                            <div className="StepsBoxWrapper">
                                {selectedBank && Object.keys(selectedBank).length ? (I18n.lang == 'en' ? selectedBank?.steps_1bill?.en : selectedBank?.steps_1bill?.ur).map((item, index) => {
                                    return (
                                        <div className="easypaisaAppStepsBoxList" key={index}>
                                            <ParagraphText className="fontSize12 fontWeight400 darkGrayTwoColor marBot5">
                                                {`Step ${index + 1}`}
                                            </ParagraphText>
                                            <ParagraphText className="fontSize12 fontWeight500 darkGrayColor">
                                                {item}
                                            </ParagraphText>
                                        </div>
                                    );
                                }) : null}
                            </div>

                            <BankDetailCard
                                data={{
                                    heading: consumer_number,
                                    handleCopy: handleCopy,
                                    handleClick: () => { },
                                    icon: <OneBillIcon />,
                                    title: "Consumer ID",
                                    paragraph: "1 bill",
                                    key: 1,
                                }}
                            />
                        </Col>
                    </Row>
                </Container>

                <div className="homeActionBox">
                    <PrimaryButton
                        data={{
                            className: "width40 fontSize16 fontWeight600 textUppercase buttonStyle BtnGreenBg whiteColor",
                            onClick: handleBankVideo,
                            title: "Watch Tutorial",
                            type: "submit",
                        }}
                    />
                </div>

            </div>
        </div>
    );
};

export default BankDetail;
