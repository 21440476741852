import { useTxnsDetailContext } from "../../../context/TxnsDetailContext";
import { LanguageContext } from "../../../context/LanguageContext";
import React, { useEffect, useContext } from "react";
import ur from "../../../languageConfig/local/ur";
import en from "../../../languageConfig/local/en";
import { Container, Row, Col } from "reactstrap";
import ParagraphText from "../ParagraphText";
import "./style.css";
import {
  WatsappIcon,
  UdhaarIcon,
  PhoneIcon,
} from "../../../assets/icons/newicon/icon";

const AppHeader = ({ data }) => {
  const { title } = data;

  const [I18n, setLanguage] = useContext(LanguageContext);
  const { payDetails } = useTxnsDetailContext();

  useEffect(() => {
    const res = localStorage.getItem("language");

    if (res) {
      changeLag(res);
    }
  }, []);

  const changeLag = (lang) => {
    if (lang == "ur") {
      setLanguage(ur);
    } else if (lang == "en") {
      setLanguage(en);
    }

    localStorage.setItem("language", lang);
  };

  const handleCall = () => {
    window.location.href = `tel:+92${payDetails?.phone_number.slice(1, 11)}`;
  };

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${payDetails?.phone_number}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <header className="newHeader">
      <Container>
        <Row>
          <Col sm={9} xs={9}>
            <div className="headerLeftWrapper">
              <ParagraphText className="fontSize14 fontWeight600 darkGrayColor OneLineTruncate">
                {payDetails?.store_name || title}
              </ParagraphText>
              <ParagraphText
                className="headerwatsappIcon"
                onClick={handleWhatsAppClick}
              >
                <WatsappIcon />
              </ParagraphText>
              <ParagraphText onClick={handleCall}>
                <PhoneIcon />
              </ParagraphText>
            </div>
          </Col>
          <Col sm={3} xs={3}>
            <div className="headerRightWrapper">
              <ParagraphText>
                <UdhaarIcon />
              </ParagraphText>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default AppHeader;
