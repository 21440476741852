import React from "react";

const ParagraphText = ({ className, children, ...rest }) => {
  return (
    <p className={`marBot0 ${className}`} {...rest}>
      {children}
    </p>
  );
};

export default ParagraphText;
