import React, { useState, useEffect } from "react";
import "./style.css";
import ParagraphText from "../ParagraphText";

const AppFloatingLableInput = ({ data }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { title, value, onChange, onBlur, prefixIcon, autoFocus = false, ...rest } = data;

  useEffect(() => {
    if (autoFocus) {
      setIsFocused(true)
    }
  }, [autoFocus]);
  
  return (
    <div className={`input-container ${value || isFocused ? "focused" : ""}`}>
      <label className="floating-label">{title}</label>
      <input
        type="text"
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="custom-input"
        {...rest}
      />
      <ParagraphText className="prfixIcon">{prefixIcon}</ParagraphText>
    </div>
  );
};

export default AppFloatingLableInput;
