import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css"; // Import custom CSS for animation
import {
  ChatIcon,
  CloseIcon,
  HelpIcon,
  PhoneIcon,
} from "../../../assets/icons/newicon/icon";

const FloatinButtons = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCall = () => {
    window.location.href = 'tel:02138341166';
  };

  return (
    <div>
      {/* Main Floating Button */}
      <Button
        className={`floating-button ${isExpanded ? "floatingExpanded" : ""}`}
        onClick={toggleExpand}
      >
        {isExpanded ? (
          <span className="chatClose">
            <CloseIcon />
          </span>
        ) : (
          <span>
            <HelpIcon />
          </span> // Call Support Icon
        )}
      </Button>

      {/* Expanded Options */}
      <div className={`options ${isExpanded ? "expanded" : ""}`}>
        <Button variant="primary" className="option-button" onClick={handleCall}>
          <PhoneIcon />
        </Button>
        <Button variant="info" className="option-button" id={'chatwoot-button'}>
          <ChatIcon />
        </Button>
      </div>
    </div>
  );
};

export default FloatinButtons;
