import { createContext, useContext, useEffect, useState } from "react";
import { getPaymentDetails } from "../SyncServices";
import { logEvent } from "firebase/analytics";
import { useHistory } from "react-router-dom";
import { analytics } from "../firebase";
import { toast } from "react-toastify";
import { path } from "../constants";

// Create User Context
const TxnsDetailContext = createContext();

// Create a Provider Component
export const TxnsDetailProvider = ({ children }) => {
  const history = useHistory(); // Use the history object for routing

  const [payDetails, setPayDetails] = useState({
    customer_name: "",
    phoneEdit: false,
    phone_number: "",
    nameEdit: false,
    unique_code: "",
    business_id: "",
    store_name: "",
    invoice_id: "",
    amount: 0,
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state

  const getTxnsDetails = async () => {
    if (!navigator.onLine) {
      toast.error("Internet connection is required");
      return;
    }

    try {
      setLoading(true);
      let params = {
        type: path[1] == "i" ? "invoice" : "salebook",
        business_report_url: path[2],
        invoice_id: path[3],
      };
      const response = await getPaymentDetails(params);
      const {
        payment_methods,
        customer_phone,
        customer_name,
        unique_code,
        business_id,
        store_name,
        invoice_id,
        bill_type,
        amount,
        status,
      } = response || {};

      logEvent(
        analytics,
        `payment_link_receipt_${status == "paid" ? "paid" : "bill"}`,
        {}
      );

      let collections = [];
      payment_methods?.map((val) => {
        if (val.collection) {
          collections.push({
            ...val,
            name: "Easypaisa App",
          });
        }
      });

      let payObj = {
        phoneEdit: customer_phone ? false : false,
        nameEdit: customer_name ? false : true,
        phone_number: customer_phone,
        customer_name,
        unique_code,
        business_id,
        store_name,
        invoice_id,
        amount,
        ...response,
      };

      setPayDetails(payObj);
      setPaymentMethods([...collections, ...payment_methods]);

      if (status === "paid" || bill_type === "out") {
        history?.replace(`/${path[1]}/${path[2]}/${path[3]}/receipt`, payObj);
      }
    } catch (error) {
      if (error?.response?.status == 404) setInvalid(true);
      else
        toast.error(
          error?.response?.message || "An error occured while fetching data"
        );
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  // Fetch data on provider initialization
  useEffect(() => {
    if (path[2] != 'banks' && path[2] != 'bank-detail') getTxnsDetails();
  }, []);

  return (
    <TxnsDetailContext.Provider
      value={{
        getTxnsDetails,
        payDetails,
        setPayDetails,
        paymentMethods,
        setPaymentMethods,
        loading,
        invalid,
      }}
    >
      {children}
    </TxnsDetailContext.Provider>
  );
};

// Custom Hook to use TxnsDetailContext
export const useTxnsDetailContext = () => {
  return useContext(TxnsDetailContext);
};
